import React, { useState, useRef } from 'react';
import {
    GoogleMap,
    useLoadScript,
    Marker,
    Autocomplete
} from '@react-google-maps/api';

const mapContainerStyle = {
    width: '100%',
    height: '300px'
};

const defaultCenter = {
    lat: 17.4065,
    lng: 78.4772
};

const SelectLocation = ({
    isOpen,
    setIsOpen,
    latitude,
    longitude,
    position,
    setlatitude,
    setlongitude,
    setPosition
}) => {
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: 'AIzaSyBdiHm0zeGJmyPiGRsI3eJDqoTfWPBT9eM',
        libraries: ['places'], // Required for Autocomplete
    });

    const [mapCenter, setMapCenter] = useState(defaultCenter);
    const [autocomplete, setAutocomplete] = useState(null);
    const searchInputRef = useRef(null);

    const handleMapClick = (e) => {
        const lat = e.latLng.lat();
        const lng = e.latLng.lng();
        setlatitude(lat);
        setlongitude(lng);
        setPosition({ lat, lng });
        setMapCenter({ lat, lng });
        setIsOpen(false);
    };

    const handleFindCurrentLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const lat = position.coords.latitude;
                    const lng = position.coords.longitude;
                    setlatitude(lat);
                    setlongitude(lng);
                    setPosition({ lat, lng });
                    setMapCenter({ lat, lng });
                },
                (error) => {
                    console.error('Error retrieving location:', error);
                    alert('Unable to retrieve your location.');
                }
            );
        } else {
            alert('Geolocation is not supported by this browser.');
        }
    };

    const onLoadAutocomplete = (autocompleteInstance) => {
        setAutocomplete(autocompleteInstance);
    };

    const onPlaceChanged = () => {
        if (autocomplete !== null) {
            const place = autocomplete.getPlace();
            if (place.geometry) {
                const lat = place.geometry.location.lat();
                const lng = place.geometry.location.lng();
                setlatitude(lat);
                setlongitude(lng);
                setPosition({ lat, lng });
                setMapCenter({ lat, lng });
            }
        }
    };

    if (!isLoaded) return <div>Loading...</div>;

    return (
        <>
            {isOpen && (
                <div className="modal-backdrop" onClick={() => setIsOpen(false)}>
                    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                        <Autocomplete
                            onLoad={onLoadAutocomplete}
                            onPlaceChanged={onPlaceChanged}
                        >
                            <input
                                type="text"
                                placeholder="Search for a location"
                                ref={searchInputRef}
                                style={{
                                    width: '100%',
                                    padding: '10px',
                                    marginBottom: '10px',
                                    borderRadius: '8px',
                                    border: '1px solid #ccc'
                                }}
                            />
                        </Autocomplete>

                        <GoogleMap
                            mapContainerStyle={mapContainerStyle}
                            center={mapCenter}
                            zoom={13}
                            onClick={handleMapClick}
                        >
                            {position && <Marker position={position} />}
                        </GoogleMap>

                        <div>
                            <button onClick={handleFindCurrentLocation}>
                                Find My Location
                            </button>
                            <button onClick={() => setIsOpen(false)}>Close</button>
                        </div>

                        <div>
                            <p>Latitude: {latitude?.toFixed(6)}</p>
                            <p>Longitude: {longitude?.toFixed(6)}</p>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default SelectLocation;
