import React, { useEffect, useState } from 'react';

import Navbar from '../../Components/Navbar'
import Footer from '../../Components/Footer'
import img from '../../Assets/image 4.png'
import { Link, useNavigate } from 'react-router-dom';
import { Create_Api } from '../../ApiUrls';
import { toast } from 'react-toastify';
import { Eye, EyeOff } from 'lucide-react';

function UserForgotPassword() {
    const navigate = useNavigate()
    const [IsUploading, setIsUploading] = useState(false);
    const [Email, setEmail] = useState('');
    const [ViewPassword, setViewPassword] = useState(false);


    const [city, setcity] = useState('');

    const localcity = localStorage.getItem("city");

    useEffect(() => {
        setcity(localcity || 'Indore')
    }, []);


    const [Otp, setOtp] = useState("");
    const [GeneratedOtp, setGeneratedOtp] = useState("");


    const [ShowOtp, setShowOtp] = useState(false);
    const [ShowPassword, setShowPassword] = useState(false);
    const [NewPassword, setNewPassword] = useState("");
    const [ConfirmPassword, setConfirmPassword] = useState("");

    const sendOtp = async () => {
        if (!Email) {
            toast.error("Email is Required");
            return;
        }

        const otp = Math.floor(1000 + Math.random() * 9000).toString();
        setGeneratedOtp(otp);

        try {
            setIsUploading(true);
            let data = { Email, otp };
            const response = await Create_Api("api/user/ForgotPasswordSendEmailOtp", data);
            toast.success(response?.message || "OTP sent successfully!");
            setShowOtp(true);
        } catch (error) {
            toast.error(error?.response?.data?.message || "Error sending OTP");
        } finally {
            setIsUploading(false);
        }
    };

    const verifyOtp = () => {
        if (!Otp) {
            toast.error("Please enter the OTP");
            return;
        }

        if (Otp !== GeneratedOtp) {
            toast.error("Invalid OTP, please try again");
            return;
        }

        toast.success("OTP verified successfully!");
        setShowOtp(false)
        setShowPassword(true);
    };

    const resetPassword = async () => {
        if (!NewPassword || !ConfirmPassword) {
            toast.error("Please enter and confirm your new password");
            return;
        }

        if (NewPassword !== ConfirmPassword) {
            toast.error("Passwords do not match");
            return;
        }

        try {
            setIsUploading(true);
            let data = { Email, Password: NewPassword };
            const response = await Create_Api("api/user/ForgotUserPasswordUpdate", data);
            toast.success(response?.message || "Password reset successfully!");
            navigate('/login')
        } catch (error) {
            toast.error(error?.response?.data?.message || "Error resetting password");
        } finally {
            setIsUploading(false);
        }
    };






    return (
        <>
            <Navbar city={city} setcity={setcity} />
            <div className='login'>
                <div className="login-tebs">
                    <br />
                    <br />
                </div>
                <div className="tab-content">
                    <div className='login-section'>
                        <div className='login-left'>
                            <img src={img} />
                        </div>
                        <div className='login-right'>
                            <div className='login-right-div'>
                                <h3>Forgot Password</h3>
                                <br />
                                <br />
                                <label>Email </label>
                                <br />
                                <input
                                    placeholder='Email'
                                    value={Email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                <br />
                                {!ShowOtp && !ShowPassword && (
                                    <>
                                        <br />
                                        <br />
                                        <button className='tab-btn active' onClick={sendOtp} disabled={IsUploading}> {IsUploading ? "Sending OTP..." : "Send OTP"}</button>
                                    </>
                                )}
                                {
                                    ShowOtp &&
                                    <>
                                        <label>Otp </label>
                                        <br />
                                        <input
                                            placeholder='Enter Otp'
                                            value={Otp}
                                            onChange={(e) => setOtp(e.target.value)}
                                        />
                                        <br />
                                        <br />
                                        <button className='tab-btn active' onClick={verifyOtp} disabled={IsUploading}>
                                            {IsUploading ? "Please wait..." : "Verify Otp"}
                                        </button>
                                    </>
                                }
                                {
                                    ShowPassword &&
                                    <>
                                        <div style={{ position: 'relative', width: '100%' }}>
                                            <label>New Password </label>
                                            <br />
                                            <input
                                                placeholder='Enter Password'
                                                type={ViewPassword ? 'text' : 'password'}
                                                value={NewPassword}
                                                onChange={(e) => setNewPassword(e.target.value)}
                                            />
                                            <span
                                                onClick={() => setViewPassword(!ViewPassword)}
                                                style={{
                                                    position: 'absolute',
                                                    right: '10px',
                                                    top: '62%',
                                                    transform: 'translateY(-50%)',
                                                    cursor: 'pointer',
                                                }}
                                            >
                                                {ViewPassword ? <EyeOff size={20} /> : <Eye size={20} />}
                                            </span>
                                        </div>
                                        <div style={{ position: 'relative', width: '100%' }}>
                                            <label>Confirm Password </label>
                                            <br />
                                            <input
                                                placeholder='Enter Password'
                                                type={ViewPassword ? 'text' : 'password'}
                                                value={ConfirmPassword}
                                                onChange={(e) => setConfirmPassword(e.target.value)}
                                            />
                                            <span
                                                onClick={() => setViewPassword(!ViewPassword)}
                                                style={{
                                                    position: 'absolute',
                                                    right: '10px',
                                                    top: '62%',
                                                    transform: 'translateY(-50%)',
                                                    cursor: 'pointer',
                                                }}
                                            >
                                                {ViewPassword ? <EyeOff size={20} /> : <Eye size={20} />}
                                            </span>
                                        </div>
                                        <button onClick={resetPassword} disabled={IsUploading} className='tab-btn active'>
                                            {IsUploading ? "Please wait..." : "Reset Password "}
                                        </button>
                                    </>
                                }
                                <br />
                                <br />
                                <p>Don’t Have an Account?</p>
                                <br />
                                <Link style={{ textDecoration: 'none' }} to='/signup'>
                                    <h4>Create Account?</h4>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default UserForgotPassword