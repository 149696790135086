import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import Registration from "./Pages/Registration";
import Coach from "./Pages/Coach";
import VenuesDetail from "./Pages/VenuesDetail";
import AvailableSlot from "./Pages/AvailableSlot";
import SportTurf from "./Pages/SportTurf";
import CoachDetail from "./Pages/CoachDetail";
import CoachAvailable from "./Pages/CoachAvailable";
import AddTurf from "./Pages/add/AddTurf";
import SlotInfo from "./Pages/add/SlotInfo";
import AddSportTurf from "./Pages/add/AddSportTurf";
import AddInstitutions from "./Pages/add/AddInstitutions";
import Profile from "./Pages/Profile";
import UserSignup from "./Pages/UserAuth/UserSignup";
import UserLogin from "./Pages/UserAuth/UserLogin";
import { ToastContainer } from 'react-toastify';
import ProgramDetails from "./Pages/ProgramDetails";
import Turfvanues from "./Pages/Turfvanues";
import TurfDetails from "./Pages/TurfDetails";
import CreateGame from "./Pages/Game/CreateGame";
import CreateTeam from "./Pages/Game/CreateTeam";
import GetScoreCard from "./Pages/Game/GetScoreCard";
import Signup from "./Pages/Coach/Turf/Signup";
import Login from "./Pages/Coach/Turf/Login";
import TurfownerAllTurfs from "./Pages/TurfownerAllTurfs";
import TurfOwnerTurfDetails from "./Pages/TurfOwnerTurfDetails";
import TurfownerAvailableslots from "./Pages/TurfownerAvailableslots";
import AddCoach from "./Pages/add/AddCoach";
import AddPrograms from "./Pages/add/AddPrograms";
import AllCoachOfInstitute from "./Pages/AllCoachOfInstitute";
import InstituteCoachDetails from "./Pages/InstituteCoachDetails";
import AllBookingOfATurf from "./Pages/AllBookingOfATurf";
import AllPrograms from "./Pages/AllPrograms";
import AddInstitute from "./Pages/add/AddInstitute";
import Myinstitute from "./Pages/Myinstitute";
import MyInstituteDetails from "./Pages/MyInstituteDetails";
import AllBookingsofAInstitute from "./Pages/AllBookingsofAInstitute";
import Termsandconditions from "./Pages/Static/Termsandconditions";
import Cancellationandreturn from "./Pages/Static/Cancellationandreturn";
import PrivacyPolicies from "./Pages/Static/PrivacyPolicies";
import SuccessBooking from "./Pages/SuccessBooking";
import FailedBooking from "./Pages/FailedBooking";
import UserForgotPassword from "./Pages/UserAuth/UserForgotPassword";
import TurfOwnerForgotPassword from "./Pages/UserAuth/TurfOwnerForgotPassword";
import InstituteForgotPassword from "./Pages/UserAuth/InstituteForgotPassword";





function App() {
  return (
    <>
      <ToastContainer />
      <Router>
        <Routes>
          <Route path="/login" element={<UserLogin />} />
          <Route path="/signup" element={<UserSignup />} />
          <Route path="/user-forgot-password" element={<UserForgotPassword />} />

          <Route path="/TurfOwner/login" element={<Login />} />
          <Route path="/TurfOwner/signup" element={<Signup />} />
          <Route path="/TurfOwner/forgot-password" element={<TurfOwnerForgotPassword />} />
          <Route path="/Institute/forgot-password" element={<InstituteForgotPassword />} />

          <Route path="/" element={<Home />} />
          <Route path="/registration" element={<Registration />} />
          <Route path="/Institutes" element={<Coach />} />
          <Route path="/Turfvanues" element={<Turfvanues />} />
          <Route path="/venuesdetail" element={<VenuesDetail />} />
          <Route path="/TurfDetails/:id" element={<TurfDetails />} />
          <Route path="/availableslot/:id" element={<AvailableSlot />} />
          <Route path="/sportturf/:id" element={<SportTurf />} />
          <Route path="/coachdetail/:id" element={<CoachDetail />} />
          <Route path="/ProgramDetails/:id" element={<ProgramDetails />} />
          <Route path="/coachavailable" element={<CoachAvailable />} />
          <Route path="/slotinfo" element={<SlotInfo />} />
          <Route path="/addsportturf" element={<AddSportTurf />} />
          <Route path="/addinstitutions" element={<AddInstitutions />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/CreateGame" element={<CreateGame />} />
          <Route path="/CreateTeam/:id" element={<CreateTeam />} />
          <Route path="/GetScoreCard/:id" element={<GetScoreCard />} />
          <Route path="/TermsandConditions" element={<Termsandconditions />} />
          <Route path="/CancellationandReturnPolicies" element={<Cancellationandreturn />} />
          <Route path="/PrivacyPolicy" element={<PrivacyPolicies />} />
          <Route path="/BookingSuccess" element={<SuccessBooking />} />
          <Route path="/FailedBooking" element={<FailedBooking />} />



          {/* Turf owner */}
          <Route path="/addturf" element={<AddTurf />} />
          <Route path="/MyAllTurfs" element={<TurfownerAllTurfs />} />
          <Route path="/TurfOwnerTurfDetails/:id" element={<TurfOwnerTurfDetails />} />
          <Route path="/TurfownerAvailableslots/:id" element={<TurfownerAvailableslots />} />
          <Route path="/AllBookingOfATurf/:id" element={<AllBookingOfATurf />} />

          {/* Institute  */}
          <Route path="/MyInstitute" element={<Myinstitute />} />
          <Route path="/MyInstituteDetails/:id" element={<MyInstituteDetails />} />
          <Route path="/AddInstitute" element={<AddInstitute />} />
          <Route path="/AddCoach/:id" element={<AddCoach />} />
          <Route path="/AddPrograms/:id" element={<AddPrograms />} />
          <Route path="/AllCoachOfInstitute" element={<AllCoachOfInstitute />} />
          <Route path="/InstituteCoachDetails/:id" element={<InstituteCoachDetails />} />
          <Route path="/AllBookingsofAInstitute/:id" element={<AllBookingsofAInstitute />} />
          <Route path="/AllPrograms" element={<AllPrograms />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
