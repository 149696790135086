import React, { useEffect, useState } from 'react';
import Navbar from '../../Components/Navbar'
import Footer from '../../Components/Footer'
import { apiPostFormData } from '../../ApiUrls';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
function AddCoach() {
    const navigate = useNavigate();
    const { id } = useParams();
    const [selectedImages, setSelectedImages] = useState([]);
    const [Images, setImages] = useState([]);
    const handleImageChange = (e, index) => {
        const file = e.target.files[0];
        if (file) {
            setSelectedImages((prevImages) => {
                const updatedImages = [...prevImages];
                updatedImages[index] = file;
                return updatedImages;
            });
        }
    };

    const removeImage = (index) => {
        const newImages = selectedImages.filter((_, i) => i !== index);
        setSelectedImages(newImages);
    };


    const [Name, setName] = useState('');
    const [Email, setEmail] = useState('');
    const [Game, setGame] = useState('');
    const [Location, setLocation] = useState('');
    const [CoachDescription, setCoachDescription] = useState('');
    const [startTime, setstartTime] = useState('');
    const [endTime, setendTime] = useState('');
    const [Experience, setExperience] = useState('');
    const [About, setAbout] = useState('');
    const [Services, setServices] = useState('');
    const [Achievements, setAchievements] = useState('');


    const [IsUploading, setIsUploading] = useState(false);

    const CreateCoach = async () => {
        try {
            const token = localStorage.getItem("userToken");

            if (!token) {
                toast.error('Please Login First....');
                return;
            }
            if (!Name) return toast.error('Name is required');
            if (!Email) return toast.error('Email is required');
            if (!Game) return toast.error('Please select Game');
            if (!Location) return toast.error('Location is required');
            if (!CoachDescription) return toast.error('Descriptions is required');
            if (!startTime) return toast.error('start Time is required');
            if (!endTime) return toast.error('end Time is required');
            if (!Experience) return toast.error('Experience is required');
            if (!About) return toast.error('About is required');
            if (!Services) return toast.error('Services is required');
            if (!Achievements) return toast.error('Achievements is required');
            if (selectedImages.length === 0) return toast.error(' Images are required');
            setIsUploading(true);
            const formData = new FormData();
            formData.append('Institute', id);
            formData.append('Name', Name);
            formData.append('Email', Email);
            formData.append('Game', Game);
            formData.append('Location', Location);
            formData.append('CoachDescription', CoachDescription);
            formData.append('Experience', Experience);
            formData.append('About', About);
            formData.append('Services', Services);
            formData.append('Achievements', Achievements);
            formData.append('Services', Services);
            formData.append('Time', JSON.stringify({
                startTime,
                endTime
            }));
            selectedImages.forEach((image, index) => {
                formData.append(`Images`, image);
            });
            const response = await apiPostFormData('api/coach/AddCoach', formData);
            console.log(response);
            if (response?.statusCode === 200) {
                setName('');
                setEmail('');
                setGame('');
                setLocation('');
                setCoachDescription('');
                setstartTime('');
                setendTime('');
                setExperience('');
                setAbout('');
                setServices('');
                setAchievements('');
                setImages([]);
                setSelectedImages([]);
                setIsUploading(false);
                toast.success(response?.message || 'Coach created successfully');
                navigate('/')
            }
            else {
                toast.error(response?.message || 'Coach created successfully');
                setIsUploading(false);
            }
        } catch (error) {
            toast.error(error?.response?.message || 'Error creating Coach');
            setIsUploading(false);
            console.error('Error creating Coach:', error);
        }
    };

    const [city, setcity] = useState('');

    const localcity = localStorage.getItem("city");

    useEffect(() => {
        setcity(localcity || 'Indore')
    }, []);



    return (
        <>
            <Navbar city={city} setcity={setcity} />
            <div className='addturf-div'>
                <div className='addturf'>
                    <h1> Add Coach</h1>
                    <br />
                    <br />
                    <div>
                        <div className='addtruf-ips'>
                            <div className='addtruf-ip'>
                                <label>Name</label>
                                <input
                                    placeholder='Name'
                                    value={Name}
                                    onChange={(e) => { setName(e.target.value) }}
                                />
                            </div>
                            <div className='addtruf-ip'>
                                <label>Email</label>
                                <input
                                    placeholder='Email'
                                    value={Email}
                                    onChange={(e) => { setEmail(e.target.value) }}
                                />
                            </div>
                        </div>
                        <br />
                        <div className='addtruf-ips'>
                            <div className='addtruf-ip'>
                                <label>Game</label>
                                <select
                                    value={Game}
                                    onChange={(e) => setGame(e.target.value)}
                                >
                                    <option value="" disabled>Select</option>
                                    <option value={'Cricket'}>Cricket</option>
                                    <option value={'Football'}>Football</option>
                                    <option value={'Tennis'}>Tennis</option>
                                </select>
                            </div>
                            <div className='addtruf-ip'>
                                <label>Location</label>
                                <input
                                    placeholder='Location'
                                    value={Location}
                                    onChange={(e) => { setLocation(e.target.value) }}
                                />
                            </div>

                        </div>
                        <br />

                        <div className='addtruf-ips'>
                            <div className='addtruf-ip'>
                                <label>CoachDescription</label>
                                <input
                                    placeholder='CoachDescription'
                                    value={CoachDescription}
                                    onChange={(e) => { setCoachDescription(e.target.value) }}
                                />

                            </div>
                            <div className='addtruf-ip'>
                                <label>Experience</label>
                                <input
                                    placeholder='Experience'
                                    value={Experience}
                                    onChange={(e) => { setExperience(e.target.value) }}
                                />
                            </div>

                        </div>
                        <br />
                        <div className='addtruf-ips'>
                            <div className='addtruf-ip'>
                                <label>startTime</label>
                                <input
                                    placeholder='startTime'
                                    value={startTime}
                                    type='time'
                                    onChange={(e) => { setstartTime(e.target.value) }}
                                />

                            </div>
                            <div className='addtruf-ip'>
                                <label>endTime</label>
                                <input
                                    placeholder='endTime'
                                    value={endTime}
                                    type='time'
                                    onChange={(e) => { setendTime(e.target.value) }}
                                />
                            </div>
                        </div>
                        <div className='addtruf-ips'>
                            <div className='addtruf-ip'>
                                <label>About</label>
                                <input
                                    placeholder='About'
                                    value={About}
                                    onChange={(e) => { setAbout(e.target.value) }}
                                />

                            </div>
                            <div className='addtruf-ip'>
                                <label>Services</label>
                                <input
                                    placeholder='Services'
                                    value={Services}
                                    onChange={(e) => { setServices(e.target.value) }}
                                />
                            </div>
                        </div>
                        <br />
                        <div className='addtruf-ips'>
                            <div className='addtruf-ip'>
                                <label>Coach Images</label>
                                <div style={{ display: "flex", gap: "8px", flexWrap: "wrap" }}>
                                    {selectedImages?.map((image, index) => (
                                        <div
                                            key={index}
                                            style={{
                                                border: "1px solid #ccc",
                                                padding: "8px",
                                                width: "80px",
                                                height: "80px",
                                                position: "relative",
                                                borderRadius: "8px",
                                                overflow: "hidden",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <input
                                                type="file"
                                                accept="image/*"
                                                onChange={(e) => handleImageChange(e, index)}
                                                style={{
                                                    position: "absolute",
                                                    width: "100%",
                                                    height: "100%",
                                                    opacity: 0,
                                                    cursor: "pointer",
                                                }}
                                            />
                                            {image ? (
                                                <img
                                                    src={URL.createObjectURL(image)}
                                                    alt={`Selected ${index + 1}`}
                                                    style={{
                                                        width: "100%",
                                                        height: "100%",
                                                        objectFit: "cover",
                                                    }}
                                                />
                                            ) : (
                                                <p
                                                    style={{
                                                        textAlign: "center",
                                                        color: "#aaa",
                                                        fontSize: "14px",
                                                    }}
                                                >
                                                    Click to select
                                                </p>
                                            )}
                                        </div>
                                    ))}
                                    <div
                                        style={{
                                            border: "1px dashed #aaa",
                                            padding: "8px",
                                            width: "80px",
                                            height: "80px",
                                            position: "relative",
                                            borderRadius: "8px",
                                            overflow: "hidden",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            cursor: "pointer",
                                        }}
                                        onClick={() => setSelectedImages([...selectedImages, null])}
                                    >
                                        <p style={{ textAlign: "center", color: "#aaa", fontSize: "14px" }}>
                                            +
                                        </p>
                                    </div>
                                </div>

                            </div>
                            <div className='addtruf-ip'>
                                <label>Achievements</label>
                                <input
                                    placeholder='Achievements'
                                    value={Achievements}
                                    onChange={(e) => { setAchievements(e.target.value) }}
                                />
                            </div>
                        </div>
                        <br />
                        <br />
                        <div className='center-btn'>
                            {IsUploading ? (
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 50 }}>
                                    <ClipLoader color="#31ED31" size={50} />
                                </div>
                            ) : (
                                <button onClick={() => { CreateCoach() }}>Submit</button>
                            )}
                        </div>
                    </div>

                </div>
            </div>
            <Footer />

        </>
    )
}

export default AddCoach