import * as React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";

export default function TimePickerComponent({ timeSlot, handleTimeChange, index, label, timeType }) {
  const [time, setTime] = React.useState(timeSlot?.[timeType] ? dayjs(`1970-01-01T${timeSlot[timeType]}`) : dayjs());

  const handleTimeSelect = (newTime) => {
    setTime(newTime);
    handleTimeChange(index, timeType, newTime?.format("HH:mm"));
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <TimePicker sx={{height:20}} label={label} value={time} onChange={handleTimeSelect} />
    </LocalizationProvider>
  );
}
