import React, { useEffect, useState } from 'react';
import location from '../Assets/location.svg';
import Indore from '../Assets/Indore.jpeg';
import { Get_Api } from '../ApiUrls';
import { toast } from 'react-toastify';

const SelectCity = ({ isOpen, setIsOpen, selectedCity, city, handleUpdateSelectedCity }) => {
  const [searchQuery, setSearchQuery] = useState('');


  const [cities, setcities] = useState([]);

  const [IsUploading, setIsUploading] = useState(false);

  const getAllCity = async () => {
    try {
      setIsUploading(true);
      const response = await Get_Api(`api/admin/GetAllCity`);
      setcities(response.data);
    } catch (error) {
      toast.error('Please correct the Game ID');
      console.error('Error fetching data:', error);
    } finally {
      setIsUploading(false);
    }
  };

  useEffect(() => {
    getAllCity();
  }, []);

  const filteredCities = cities.filter((e) =>
    e.Name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <>
      {isOpen && (
        <div className="modal-backdrop" onClick={() => setIsOpen(false)}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                <div className="search-container">
                  <i className="bi bi-search"></i>
                  <input
                    type="text"
                    placeholder={selectedCity ? selectedCity : "Search for your city"}
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="search-input"
                  />
                </div>
                <p className="popular-cities-text">Popular Cities</p>
                <div className="popular-cities">
                  {filteredCities.length > 0 ? (
                    filteredCities.map((e, i) => (
                      <div className="city-item" key={i}>
                        <img
                          src={e.Image || Indore}
                          onClick={() => handleUpdateSelectedCity(e.Name)}
                          className="city-image"
                          alt={e.cityName}
                        />
                        <p className="city-name">{e.Name}</p>
                      </div>
                    ))
                  ) : (
                    <p className="no-results-text">No cities found</p>
                  )}
                </div>
              <button className='button' onClick={()=>{setIsOpen(false)}}>
                Close
              </button>
            </div>
          </div>
      )}
    </>
  );
};

export default SelectCity;
