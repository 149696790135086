import React, { useEffect, useState } from 'react'
import Navbar from '../../Components/Navbar'
import Footer from '../../Components/Footer'

function Termsandconditions() {
  const [city, setcity] = useState('');

  const localcity = localStorage.getItem("city");

  useEffect(() => {
    setcity(localcity || 'Indore')
  }, []);
  return (
    <div>
      <Navbar city={city} setcity={setcity} />
      <br />
      <div className="terms-container">
        <header className="terms-header">
          <h1>Terms and Conditions</h1>
        </header>

        <section className="terms-section">
          <h2>ACCEPTANCE OF TERMS OF USE AND OTHER POLICIES:</h2>
          <br />
          <br />
          <p>*** This Website is Owned and Maintained by Kreeda Connect PVT LTD *** </p>
          <br />
          <br />
          <ul className="alphabet-list">
            <li>
              All references to "you" or "your", as applicable, means any individual who accesses, uses, and/or participates in the Kreeda Connect (as defined below) in any manner ("Users"). If you use the Kreeda Connect on behalf of an organization, you confirm and warrant that you have the authority to bind that organization Terms and condtions. Your acceptance of the Terms of Use will be considered as acceptance by the organization you represent and "you" and "your" herein shall refer to such organization.
            </li>
            <li>
              Terms of Use. These terms of use (the "Terms of Use") govern your use of our website [kreedaco.com] (the "Website") and our Kreeda application for mobile and portable devices (the "App") and any assignees and permitted licenses thereof. The Website and the App are collectively referred to as the "Kreeda Connect". The Kreeda Connect is currently owned and operated by Kreeda Connect PVT LTD ("Kreeda"), a company incorporated under the Companies Act, 2013 and having its registered office at OLD MIG BHEL, Hyderabad, Telangana- 502032. "We", "us", and "our" herein shall refer to Kreeda and our associates/partners/successors/permitted assigns.
            </li>
            <li>
              Please read these Terms of Use thoroughly and carefully before you use the Kreeda Connect as they contain provisions that define your limits, legal rights and obligations with respect to your participation in the Kreeda Connect and your use of the services offered by the Kreeda Connect (such services, collectively called the "Services"). These Terms of Use and Privacy Policy (as defined below) constitute a legally binding agreement between Kreeda Connect and you. By installing, downloading or even merely using the Kreeda, and by clicking on "I accept these Terms of Use" you shall be entering into a contract with Kreeda Connect and you indicate your acceptance of the Terms of Use and other Kreeda policies (including but not limited to the Privacy Policy) as posted on the Kreeda Connect from time to time, which becomes effective on the date on which you download, install or use the Services, and create a legally binding arrangement to abide by the same. If you do not agree to these terms, you must not click to confirm your acceptance, and you are prohibited from accessing or using the Kreeda Connect. If you disagree with any provisions within the Terms of Use, please cease accessing or participating in the Services offered by the Kreeda Connect immediately. By using the Kreeda Connect, you acknowledge that the terms, conditions and disclaimers set out in these Terms of Use are reasonable.
            </li>
            <li>
              Privacy Policy. Kreeda has established a privacy policy that explains to Users how their information ("User Content") is collected and stored ("Privacy Policy"). The Privacy Policy is referenced above and hereby incorporated into the Terms of Use set forth. Your use of the Kreeda Connect is governed by the Privacy Policy, which is available at the link  Privacy Policy.
            </li>
            <li>
              Amendments to Terms of Use. These Terms of Use and any other document incorporated by reference may be modified at any time. Kreeda connect reserves the right to update or modify these Terms of Use and other Kreeda policies at any time by publishing changes at Terms of Use. You are responsible for regularly reviewing the Terms of Use and other Kreeda policies and staying informed of any changes made to the Kreeda Connect. When Kreeda Connect  implements changes to the Terms of Use, you will be notified through an on-screen notification that will require your attention. You must review and accept any revised term(s). Failure to accept updated terms will result in the termination of your existing registration.
            </li>
          </ul>
        </section>

        <section className="terms-section">
          <h2>2. USE OF PLATFORM AND SERVICES:</h2>
          <div className="policy-item">
            <h3>a. Membership Eligibility Criteria</h3>
            <ul className="alphabet-list" style={{ listStyleType: 'upper-roman' }}>
              <li>
                You can browse and view the Kreeda Connect Platform without registration. However, creating a password-protected account ("Account") is necessary for accessing specific platform features. Account creation requires providing your name, mobile number and/or email address through our registration interface, along with creating a password. While not mandatory for registration, you may provide additional information to help Kreeda Connect deliver a more personalized experience. You also have the option to register using your existing social media or other digital platform credentials (your "Third-Party Site Account"). Users who register for an Account are referred to as "Platform Members" on the Kreeda Connect Platform.
              </li>
              <li>
                Only individuals capable of forming legally binding contracts under applicable law may use the Kreeda Connect Platform. By creating an account, you confirm that: (a) you are at least 16 years of age, (b) all information submitted during registration is accurate and truthful, (c) you will keep your information up to date, and (d) your platform usage complies with all relevant laws and regulations. If we believe you are under 16 or are violating applicable laws or regulations, your Account may be terminated without prior notice. For underage users accessing the platform, we assume such access is supervised and authorized by a parent or legal guardian.
              </li>
              <li>
                Kreeda Connect reserves the right to suspend or terminate your Account and service access if: (i) any information provided during or after registration proves false, outdated, or incomplete; (ii) your actions could create legal liability for yourself, other users, or our platform; and/or       (iii) you fail to comply with these Terms of Use.
              </li>
              <li>
                You must protect your password security. You agree to: maintain password confidentiality, accept full responsibility for all Account activities, promptly notify Kreeda Connect of any security breaches or unauthorized access, never use another member's Account without Kreeda Connect's explicit permission, and maintain honest self-representation. You acknowledge full responsibility for any losses or damages resulting from unauthorized use of your Account, whether affecting you, Kreeda Connect, or other parties.
              </li>
            </ul>
          </div>
          <br />
          <br />
          <div className="policy-item">
            <h2>Use of the Platform</h2>
            <h3> Use of the Platform. Your use of the Kreeda Connect Platform must strictly adhere to these fundamental principles:            </h3>
            <div style={{ marginLeft: 20 }}>
              <p>i.  You shall not host, display, upload, download, modify, publish, transmit, update or share any information :
              </p>
              <ul className="alphabet-list">
                <li>
                  1.  Which belongs to another person and which you do not have any right to;
                </li>
                <li>
                  2.  Which is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic, paedophilic, libellous, slanderous, criminally inciting or invasive of another's privacy, hateful, or racially, ethnically objectionable, disparaging, relating or encouraging money laundering or gambling, or otherwise unlawful in any manner whatsoever; or unlawfully threatening or unlawfully harassing including but not limited to any content which is in violation of the provisions of the Indian Penal Code, 1860;
                </li>
                <li>
                  3. Which is misleading or misrepresentative in any way;
                </li>
                <li>
                  4.  Which is patently offensive to the online community, such as sexually explicit content, or content that promotes obscenity, paedophilia, racism, bigotry, hatred or physical harm of any kind against any group or individual;
                </li>
                <li>
                  5.  Which harasses or advocates harassment of another person;
                </li>
                <li>
                  6.  Which involves the transmission of "junk mail", "chain letters", or unsolicited mass mailing or "spamming";
                </li>
                <li>
                  7. Which promotes illegal activities or conduct that is abusive, threatening, obscene, defamatory or libellous;</li>
                <li>
                  8. Which infringes upon or violates any third party's rights [including, but not limited to, intellectual property rights, rights of privacy (including without limitation unauthorized disclosure of a person's name, email address, physical address or phone number) or rights of publicity];
                </li>
                <li>
                  9.  Which promotes an illegal or unauthorized copy of another person's copyrighted work (see "copyright complaint" below for instructions on how to lodge a complaint about uploaded copyrighted material), such as providing pirated computer programs or links to them, providing information to circumvent manufacture-installed copy-protect devices, or providing pirated music or links to pirated music files;
                </li>
                <li>
                  10. Which contains restricted or password-only access pages, or hidden pages or images (those not linked to or from another accessible page);</li>
                <li>
                  11. Which provides material that exploits people in a sexual, violent or otherwise inappropriate manner or solicits personal information from anyone;</li>
                <li>
                  12. Which provides instructional information about illegal activities such as making or buying illegal weapons, violating someone's privacy, or providing or creating computer viruses;
                </li>
                <li>
                  13.  Which contains video, photographs, or images of another person (with a minor or an adult);
                </li>
                <li>
                  14.  Which tries to gain unauthorized access or exceeds the scope of authorized access to the Kreeda Platform or to profiles, blogs, communities, account information, bulletins, friend request, or other areas of the Kreeda Platform or solicits passwords or personal identifying information for commercial or unlawful purposes from other Users;
                </li>
                <li>
                  15. Which engages in commercial activities and/or sales without our prior written consent such as contests, sweepstakes, barter, advertising and pyramid schemes, or the buying or selling of products related to the Kreeda Platform. Throughout these Terms of Use, Kreeda Connect's prior written consent means a communication coming from Kreeda Connect's legal department/authorised representative, specifically in response to your request, and expressly addressing and allowing the activity or conduct for which you seek authorization;
                </li>
                <li>
                  16. Which solicits gambling or engages in any gambling activity which is or could be construed as being illegal;
                </li>
                <li>
                  17. Which interferes with another User's use and enjoyment of the Kreeda Platform or any third party User and enjoyment of similar services;</li>
                <li>
                  18.  Which refers to any website or URL that, in our sole discretion, contains material that is inappropriate for the Kreeda Platform or any other website, contains content that would be prohibited or violates the letter or spirit of these Terms of Use;
                </li>
                <li>
                  19.  Which harm minors in any way.
                </li>
                <li>
                  20. Which Infringes any patent, trademark, copyright or other intellectual property rights or third party's trade secrets or rights of publicity or privacy or shall not be fraudulent;
                </li>
                <li>
                  21. Which violates any law for the time being in force
                </li>
                <li>
                  22.  Which deceives or misleads the addressee/Users about the origin of such messages or communicates any information which is grossly offensive or menacing in nature;
                </li>
                <li>
                  23.  Which impersonate another person;
                </li>
                <li>
                  24. Which contains software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer resource; or contains any trojan horses, worms, time bombs, cancelbots, easter eggs or other computer programming routines that may damage, detrimentally interfere with, diminish value of, surreptitiously intercept or expropriate any system, data or personal information;

                </li>
                <li>
                  25. Which threatens the unity, integrity, defence, security or sovereignty of India, friendly relations with foreign states, or public order or causes incitement to the commission of any criminal offence or prevents investigation of any offence or is insulting any other nation;

                </li>
                <li>
                  26. Which is false, inaccurate or misleading;

                </li>
                <li>
                  27. Which directly or indirectly, offers, attempts to offer, trades or attempts to trade in any item, the dealing of which is prohibited or restricted in any manner under the provisions of any applicable law, rule, regulation or guideline for the time being in force; or

                </li>
                <li>
                  28.  Which creates liability for us or causes us to lose (in whole or in part) the services of our internet service provider or other suppliers.

                </li>
              </ul>
              <br />

              <p>ii.  You shall not conduct any systematic or automated data collection activities (including scraping, data mining, data extraction, data harvesting, 'framing' (iframes), article 'spinning') on or in relation to the Kreeda Platform without express written consent from Kreeda Connect.
              </p>
              <br />
              <p>iii. You shall not use any "deep-link", "page-scrape", "robot", "spider" or other automatic device, program, algorithm or methodology, or any similar or equivalent manual process, to access, acquire, copy or monitor any portion of the Kreeda Platform or any content, or in any way reproduce or circumvent the navigational structure or presentation of the Kreeda Platform or any content, to obtain or attempt to obtain any materials, documents or information through any means not purposely made available through the Kreeda Platform. We reserve our right to prohibit any such activity</p>
              <br />
              <p>iv. You shall not attempt to gain unauthorized access to any portion or feature of the Kreeda Platform, or any other systems or networks connected to the Kreeda Platform or to any server, computer, network, or to any of the services offered on or through the Kreeda Platform, by hacking, "password mining" or any other illegitimate means.
              </p>
              <br />
              <p>v. You shall not probe, scan or test the vulnerability of the Kreeda Platform or any network connected to the Kreeda Platform nor breach the security or authentication measures on the Kreeda Platform or any network connected to the Kreeda Platform. You may not reverse look-up, trace or seek to trace any information on any other User of or visitor to the Kreeda Platform, or any other Buyer, including any account on the Kreeda Platform not owned by you, to its source, or exploit the Kreeda Platform or any service or information made available or offered by or through the Kreeda Platform, in any way where the purpose is to reveal any information, including but not limited to personal identification or information, other than your own information, as provided for by the Kreeda Platform.
              </p>
              <br />
              <p>vi. You shall not make any negative, denigrating or defamatory statement(s) or comment(s) about us or the brand name or domain name used by us including the name 'Kreeda Connect', or otherwise engage in any conduct or action that might tarnish the image or reputation, of Kreeda Connect on the Kreeda Platform or otherwise tarnish or dilute any of Kreeda Connect's trade or service marks, trade name and/or goodwill associated with such trade or service marks, as may be owned or used by us. You agree that you will not take any action that imposes an unreasonable or disproportionately large load on the infrastructure of the Kreeda Platform or Kreeda Connect's systems or networks, or any systems or networks connected to Kreeda Connect.
              </p>
              <br />
              <p>vii. You agree not to use any device, software or routine to interfere or attempt to interfere with the proper working of the Kreeda Platform or any transaction being conducted on the Kreeda Platform, or with any other person's use of the Kreeda Platform.
              </p>
              <br />
              <p>vii. You may not forge headers or otherwise manipulate identifiers in order to disguise the origin of any message or transmittal you send to us on or through the Kreeda Platform or any service offered on or through the Kreeda Platform. You may not pretend that you are, or that you represent, someone else, or impersonate any other individual or entity.
              </p>
              <br />
              <p>ix. You may not use the Kreeda Platform or any content on the Palyo Platform for any purpose that is unlawful or prohibited by these Terms of Use, or to solicit the performance of any illegal activity or other activity that infringes the rights of Kreeda Connect and/or others.
              </p>
              <br />
              <p>x. You shall at all times ensure full compliance with the applicable provisions, as amended from time to time, of (a) the Information Technology Act, 2000, India and the rules thereunder; (b) all applicable domestic laws, rules and regulations (including the provisions of any applicable exchange control laws or regulations in force); and (c) international laws, foreign exchange laws, statutes, ordinances and regulations (including, but not limited to goods and service taxes, income tax, and local levies) regarding your use of our Service and your listing, purchase, solicitation of offers to avail our Services. You shall not engage in any transaction in an item or service, which is prohibited by the provisions of any applicable law including exchange control laws or regulations for the time being in force.
              </p>
              <br />
              <p>xi. In order to allow us to use the User Content supplied by you, without violating your rights or any laws, you agree to grant us a non-exclusive, worldwide, perpetual, irrevocable, royalty-free, sub-licensable (through multiple tiers) right to exercise the copyright, publicity, database rights or any other rights you have in your Information, in any media now known or not currently known, with respect to your Information. We will only use your information in accordance with these Terms of Use and the Privacy Policy applicable to use of the Kreeda Platform.
              </p>
              <br />
              <p>xii. We will monitor your messages and interaction on the Kreeda Platform and if you indulge in any form of advertising or solicitation, we reserve the right to restrict the number of messages or emails which a User may send to other Users in any 24-hour period which we deem appropriate in its sole discretion. You understand that we have the right at all times to disclose any information (including the identity of the persons providing information or materials on the Kreeda Platform) as necessary to satisfy any law, regulation or valid governmental request. This may include, without limitation, disclosure of the information in connection with investigation of alleged illegal activity or solicitation of illegal activity or in response to a lawful court order or subpoena. In addition, We can (and you hereby expressly authorize us to) disclose any information about you to law enforcement or other government officials, as we, in our sole discretion, believe necessary or appropriate in connection with the investigation and/or resolution of possible crimes, especially those that may involve personal injury.
              </p>
              <br />
              <p>xiii. We reserve the right, but have no obligation, to monitor the materials posted on the Kreeda Platform. Kreeda Connect shall have the right to remove or edit any content that in its sole discretion violates, or is alleged to violate, any applicable law or either the spirit or letter of these Terms of Use. Notwithstanding this right, you remain solely responsible for the content of the materials you post on the Kreeda Platform and in your private messages. Please be advised that such content posted does not necessarily reflect Kreeda Connect's views. In no event shall Kreeda Connect assume or have any responsibility or liability for any content posted or for any claims, damages or losses resulting from use of content and/or appearance of content on the Kreeda Platform. You hereby represent and warrant that you have all necessary rights in and to all content which you provide and all information it contains and that such content shall not infringe any proprietary or other rights of third parties or contain any libellous, tortious, or otherwise unlawful information.</p>
              <br />
              <p>xiv. Your correspondence or business dealings with, or participation in promotions of, advertisers found on or through the Kreeda Platform, including payment and delivery of related products or services, and any other terms, conditions, warranties or representations associated with such dealings, are solely between you and such advertiser. We shall not be responsible or liable for any loss or damage of any sort incurred as the result of any such dealings or as the result of the presence of such advertisers on the Kreeda Platform. Please note that Kreeda Connect does not warrant the quality or performance of any products or services which are hosted on the Kreeda Platform. Users are encouraged to exercise their personal discretion while choosing any product or service available on the Kreeda Platform.
              </p>
              <br />
              <p>xv. It is possible that other Users (including unauthorized Users or 'hackers') may post or transmit offensive or obscene materials on the Kreeda Platform and that you may be involuntarily exposed to such offensive and obscene materials. It also is possible for others to obtain personal information about you due to your use of the Kreeda Platform, and that the recipient may use such information to harass or injure you. We do not approve of such unauthorized uses, but by using the Kreeda Platform You acknowledge and agree that we are not responsible for the use of any personal information that you publicly disclose or share with others on the Kreeda Platform. Please carefully select the type of information that you publicly disclose or share with others on the Kreeda Platform.
              </p>
              <br />
            </div>
          </div>
          <div className="policy-item">
            <h2> TERMS OF SERVICE:
            </h2>
            <p>c. You acknowledge and agree that Kreeda Connect facilitates the hosting of products, services, events and arenas which are offered by third party providers ("Service Provider") and Kreeda Connect does not:
            </p>
            <ul>
              <li>
                i.  Make any warranties, whether express or implied of the quality of the products, services, events or arenas which are offered by the Service Providers;
              </li>
              <li>
                ii.  Endorse, or confirm that a certain product, products, services, events or arenas which are provided or offered by the Service Provider, confirm to a certain quality or has been rated in a certain format; ;
              </li>
              <li>
                iii.  Assume any liability if the products, services, events or arenas provided by the Service Provider do not meet the expectation of the User of if the User suffers any loss or damage, including any bodily injury as a consequence of using such products, services, events or arenas;;
              </li>
              <li>
                iv.  Assume any liability in the event of any change, alternation, amendment or replacement to the products, services, events or arenas offered by the Service Provider.
              </li>
            </ul>
            <br />
            <p>d.  You might be required to provide your credit or debit card details to the approved payment gateways while making the payment. In this regard, you agree to provide correct and accurate credit/ debit card details to the approved payment gateways for availing the Services. You shall not use the credit/ debit card which is not lawfully owned by you, i.e. in any transaction, you must use your own credit/ debit card. The information provided by you will not be utilized or shared with any third party unless required in relation to fraud verifications or by law, regulation or court order. You will be solely responsible for the security and confidentiality of your credit/ debit card details. We expressly disclaim all liabilities that may arise as a consequence of any unauthorized use of your credit/ debit card.
            </p>
            <br />
            <p>e.  The Kreeda Platform is not equipped or authorised to conclude any financial transaction. All payments shall be made through the payment gateway. Kreeda Connect will conduct the payments received through the payment gateway, or such other authorised payment system, to the Service Provider. The receipt for such payment shall be directly issued to you by the Service Provider. Please ensure that you claim an invoice from the Service Provider.
            </p>
            <br />
            <p>f.  We do not endorse any Service Provider. In addition, although these Terms of Use require you to provide accurate information, we do not attempt to confirm, and do not confirm if it is purported identity.
            </p>
            <br />
            <p>g.  We do not endorse any Service Provider. In addition, although these Terms of Use require you to provide accurate information, we do not attempt to confirm, and do not confirm if it is purported identity.
            </p>
            <br />
            <p>h.  You agree not to attempt to impose liability on, or seek any legal remedy from us with respect to any actions or omissions of the Service Provider.</p>
          </div>
          <br />
          <br />
          <div className="policy-item">
            <h2>  GENERAL TERMS OF USE:
            </h2>
            <p>a. Payments made through the Kreeda Platform will not be refunded for any reason including for not purchasing, or availing any products, services, events or arenas.
            </p>
            <br />
            <p>b.  Information provided by the Kreeda Platform shall be updated on a regular basis subject to the same being provided to us by the Service Provider. If the Service Provider amends, alters or withdraws any product, service, venue or arena and the same is not updated on the Kreeda Platform at the time of the User availing such products, services, events or arenas, then Service Provider shall ensure the replacement of the products, services, events or arenas with an equivalent offering or to refund the money receive from the User
            </p>
            <br />
            <p>c.  If you choose to use the Kreeda Platform, it shall be your responsibility to treat your User Name, password and any other piece of information that we may provide, as part of our security procedures, as confidential and not disclose the same to any person or entity other than us. We shall at times and at our sole discretion reserve the right to disable any User Name or password if you have failed to comply with any of the provisions of these Terms of Use.
            </p>
            <br />
            <p>d. The Kreeda Platform can be used to procure or avail products, services, events or arenas within select cities in India. The Kreeda Platform will provide and facilitate access only to those Service Providers who are registered on the Kreeda Platform. As we are providing Services in the select cities in India, we have complied with applicable laws of India in making the Kreeda Platform and its content available to you. In the event the Kreeda Platform is accessed from outside India or outside our operating zones, it shall be entirely at your risk. We make no representation that the Kreeda Platform and its contents are available or otherwise suitable for use outside select cities. If you choose to access or use the Kreeda Platform from or in locations outside select cities, you do so on your own and shall be responsible for the consequences and ensuring compliance of applicable laws, regulations, byelaws, licenses, registrations, permits, authorisations, rules and guidelines.
            </p>
            <br />
            <p>e.  You shall at all times be responsible for the use of the Services through your computer or mobile device and for bringing these Terms of Use and Kreeda Connect policies to the attention of all such persons accessing the Kreeda Platform on your computer or mobile device.
            </p>
            <br />
            <p>f.  You understand and agree that the use of the Services does not include the provision of a computer or mobile device or other necessary equipment to access it. You also understand and acknowledge that the use of the Kreeda Platform requires internet connectivity and telecommunication links. You shall bear the costs incurred to access and use the Kreeda Platform and avail the Services, and we shall not, under any circumstances whatsoever, be responsible or liable for such costs.
            </p>
            <br />
            <p>g.  You agree and grant permission to Kreeda Connect to receive promotional/transactional SMS and e-mails from Kreeda Connect or allied partners</p>
            <br />
            <p>h.  By using the Kreeda Platform you represent and warrant that:
            </p>
            <ul>
              <li>
                i.  All registration information you submit is truthful, lawful and accurate and that you agree to maintain the accuracy of such information.
              </li>
              <br />
              <li>
                ii.  Your use of the Kreeda Platform shall be solely for your personal use and you shall not authorize others to use your account, including your profile or email address and that you are solely responsible for all content published or displayed through your account, including any email messages, and your interactions with other Users and you shall abide by all applicable local, state, national and foreign laws, treaties and regulations, including those related to data privacy, international communications and the transmission of technical or personal data.
              </li>
              <br />
              <li>
                iii.  All necessary licenses, consents, permissions and rights are owned by you and there is no need for any payment or permission or authorization required from any other party or entity to use, distribute or otherwise exploit in all manners permitted by these Terms of Use and Privacy Policy, all trademarks, copyrights, patents, trade secrets, privacy and publicity rights and / or other proprietary rights contained in any content that you submit, post, upload, distribute or otherwise transmit or make available.
              </li>
              <br />
              <li>
                iv.  You agree to release and fully indemnify Kreeda Connect and/or any of its directors, shareholders officers and representatives from any cost, damage, liability or other consequence of any of the actions of the Users of the Kreeda Platform and specifically waive any claims that you may have in this behalf under any applicable laws of India. Without prejudice to this, you agree that the limitations and disclaimers set out in these Terms of Use will also protect the officers, employees, agents, subsidiaries, successors, assigns and sub-contractors of Kreeda Connect.
              </li>
              <br />
              <li>
                v.  Notwithstanding its reasonable efforts in that behalf, Kreeda Connect cannot take responsibility or control the information provided by other Users which is made available on the Kreeda Platform. You may find other User's information to be offensive, harmful, inconsistent, inaccurate, or deceptive. Please use caution and practice safe trading when using the Kreeda Platform.

              </li>
            </ul>
          </div>
          <br />
          <br />
          <div className="policy-item">
            <h2>4.   RELATIONSHIP WITH OPERATORS IF THE PLATFORM IS ACCESSED ON MOBILE DEVICES:
            </h2>
          </div>
          <ul className='alphabet-list' style={{ listStyleType: 'lower-alpha', marginLeft: 20 }}>
            <li>
              In the event the Kreeda Platform is accessed on a mobile device, it is not associated, affiliated, sponsored, endorsed or in any way linked to any operator, including, without limitation, Apple, Google, Android or RIM Blackberry (each being an"Operator").
            </li>
            <li>
              Your download, installation, access to or use of the Kreeda Platform is also bound by the terms and conditions of the Operator.
            </li>
            <li>
              You and we acknowledge that these Terms of Use are concluded between you and us only, and not with an Operator, and we, not those Operators, are solely responsible for the Kreeda Platform and the content thereof to the extent specified in these Terms of Use.
            </li>
            <li>
              The license granted to you for the Kreeda Platform is limited to a non-transferable license to use the Kreeda Platform on a mobile device that you own or control and as permitted by these Terms of Use.

            </li>
            <li>
              We are solely responsible for providing any maintenance and support services with respect to the Kreeda Platform as required under applicable law. You and we acknowledge that an Operator has no obligation whatsoever to furnish any maintenance and support services with respect to the Kreeda Platform.
            </li>
            <li>
              You and we acknowledge that we, not the relevant Operator, are responsible for addressing any claims of you or any third party relating to the Palyo Platform or your possession and/or use of the Palyo Platform, including, but not limited to: (i) any claim that the Kreeda Platform fails to conform to any applicable legal or regulatory requirement; and (ii) claims arising under consumer protection or similar legislation.
            </li>
            <li>
              You and we acknowledge that, in the event of any third party claim that the Kreeda Platform or your use of the Kreeda Platform infringes that third party's intellectual property rights, we, not the relevant Operator, will be solely responsible for the investigation, defence, settlement and discharge of any such intellectual property infringement claim.
            </li>
            <li>
              You and we acknowledge and agree that the relevant Operator, and that Operator's subsidiaries, are third party beneficiaries of these Terms of Use, and that, upon your acceptance of these Terms of Use, that Operator will have the right (and will be deemed to have accepted the right) to enforce these Terms of Use against you as a third party beneficiary thereof.
            </li>
            <li>
              You and we acknowledge and agree that the relevant Operator, and that Operator's subsidiaries, are third party beneficiaries of these Terms of Use, and that, upon your acceptance of these Terms of Use, that Operator will have the right (and will be deemed to have accepted the right) to enforce these Terms of Use against you as a third party beneficiary thereof.
            </li>
          </ul>
          <br />
          <br />
          <div className="policy-item">
            <h2>
              5.  DISCLAIMERS:
            </h2>
          </div>
          <ul className='alphabet-list' style={{ listStyleType: 'lower-alpha', marginLeft: 20 }}>
            <li>
              The Kreeda Platform may be under constant upgrades, and some functions and features may not be fully operational.
            </li>
            <li>
              Due to the vagaries that can occur in the electronic distribution of information and due to the limitations inherent in providing information obtained from multiple sources, there may be delays, omissions, or inaccuracies in the content provided on the Kreeda Platform or delay or errors in functionality of the Kreeda Platform. As a result, we do not represent that the information posted is correct in every case.
            </li>
            <li>
              We expressly disclaim all liabilities that may arise as a consequence of any unauthorized use of credit/ debit cards.
            </li>
            <li>
              You acknowledge that third party services are available on the Kreeda Platform. We may have formed partnerships or alliances with some of these third parties from time to time in order to facilitate the provision of certain services to you. however, you acknowledge and agree that at no time are we making any representation or warranty regarding any third party's services nor will we be liable to you or any third party for any consequences or claims arising from or in connection with such third party including, and not limited to, any liability or responsibility for, death, injury or impairment experienced by you or any third party. You hereby disclaim and waive any rights and claims you may have against us with respect to third parties services.
            </li>
            <li>
              Apart from a cursory background verification, Kreeda Connect does not make any representation or warranty as to the item-specifics (such as legal title, creditworthiness, identity, etc.) of any of the Users. You are advised to independently verify the bona fides of any particular User that you choose to deal with on the Kreeda Platform and use your best judgment on that behalf.
            </li>
            <li>
              While the materials provided on the Kreeda Platform were prepared to provide accurate information regarding the subject discussed, the information contained in these materials is being made available with the understanding that we make no guarantees, representations or warranties whatsoever, whether expressed or implied, with respect to professional qualifications, expertise, quality of work or other information herein. Further, we do not, in any way, endorse any service offered or described herein. In no event shall we be liable to you or any third party for any decision made or action taken in reliance on such information.
            </li>
            <li>
              The information provided hereunder is provided "as is". We and / or our employees make no warranty or representation regarding the timeliness, content, sequence, accuracy, effectiveness or completeness of any information or data furnished hereunder or that the information or data provided hereunder may be relied upon. multiple responses may usually be made available from different sources and it is left to the judgement of users based on their specific circumstances to use, adapt, modify or alter suggestions or use them in conjunction with any other sources they may have, thereby absolving us as well as our consultants, business associates, affiliates, business partners and employees from any kind of professional liability.

            </li>
            <li>
              We shall not be liable to you or anyone else for any losses or injury arising out of or relating to the information or Services provided on the Kreeda Platform. In this regard, you acknowledge that the Services offered by the Kreeda Platform concern activities that carry an inherent risk. However, we merely provide a booking platform to facilitate such activities and in no event will we or our employees, affiliates, authors or agents be liable to you or any third party for any decision made or action taken by your reliance on the Services contained herein.
            </li>
            <li>
              In no event will we be liable for any damages (including, without limitation, direct, indirect, incidental, special, consequential or exemplary damages, damages arising from personal injury/wrongful death, and damages resulting from lost profits, lost data or business interruption), resulting from any services provided by any third party through the Kreeda Platform, whether based on warranty, contract, tort, or any other legal theory and whether or not we are advised of the possibility of such damages. However, we will attempt to refund any amount paid by the User, at our sole discretion, and our liability in this regard, if any will be limited to just the amount paid by the User with respect to the said booking on the Kreeda Platform.</li>
            <li>
              Nothing in these disclaimers limits the liability of Kreeda Connect in respect of:
            </li>
            <ul style={{ marginLeft: 20 }}>
              <li>
                Death or personal injury caused by the negligence of or its agents or employees;
              </li>
              <li>Fraud or fraudulent misrepresentation by its agents or employees;</li>
              <li>Any matter which it would be illegal or unlawful for it to exclude or limit, or to attempt or purport to exclude or limit, its liability.</li>
            </ul>
            <li>
              Nothing in these Terms of Use constitutes, or is meant to constitute, advice of any kind. If you require advice in relation to any legal, financial or medical matter, you should consult an appropriate professional.
            </li>
          </ul>
          <br />
          <br />
          <div className="policy-item">
            <h2>
              6.  BREACH OF TERMS:
            </h2>
          </div>
          <ul className='alphabet-list' style={{ listStyleType: 'lower-alpha', marginLeft: 20 }}>
            <li>
              You hereby acknowledge and agree that Kreeda Connect, in its sole and absolute discretion, has the right (but not the obligation) to delete, terminate, or deactivate your Account, block your email or IP address, cancel the terminate your access to or participation in the use of the Kreeda Platform (or any part thereof), or remove and discard any content on the Kreeda Platform ("Termination"), immediately and without notice, for any reason, including without limitation, if Kreeda Connect believes or has reason to believe that you have violated any provision of the Terms of Use.</li>
            <li>
            </li>
            You hereby indemnify and undertake to keep Kreeda Connect indemnified against any losses, damages, costs, liabilities and expenses (including without limitation legal expenses and any amounts paid by to a third party in settlement of a claim or dispute) incurred or suffered by it, that arise out of any breach by you of any provision of these Terms of Use, or arising out of any claim that you have breached any provision of these Terms of Use.

            <li>
              Effect of Termination:
            </li>
            <ul style={{ marginLeft: 20 }}>
              <li>
                Upon termination of your Account, your right to participate in the Kreeda Platform shall automatically terminate. You acknowledge and agree that your right to receive any Services hereunder is conditional upon your proper use of the Kreeda Platform, your adherence to the Terms of Use, the continuous activation of your Account, and your permitted participation in the Kreeda Platform. Notwithstanding the foregoing, residual data may remain in the Kreeda Connect system.

              </li>
              <li>
                If you provided a valid email address during registration, Kreeda Connect will notify you via email of any such termination or cancellation, which shall be effective immediately upon Kreeda Connect's delivery of such notice.

              </li>
              <li>
                Upon Termination, you shall promptly destroy all copies of Kreeda Connect data and other content in your possession or control. You further acknowledge and agree that Kreeda Connect shall not be liable to you or any third party for any termination of your access to the Kreeda Platform. Upon Termination of Service, Kreeda Connect retains the right to use any data collected from your use of the Kreeda Platform for internal analysis and archival purposes. In no event is Kreeda Connect obligated to return any submitted content to you. Clauses in the Terms of Use regarding Termination, Intellectual Property, Dispute Resolution, Disclaimers, Limitations of liability, Indemnification, Treatment of information User Content, and third party content will survive expiration or termination of the Kreeda Platform and/or your Account.

              </li>
              <li>
                You agree to indemnify and hold Kreeda Connect and its officers, managers, Registered Users, affiliates, successor, assignees, directors, agents, suppliers and employees harmless from any claim or demand, including reasonable attorneys' fees and court costs, made by any third party due to or arising out of such Termination.

              </li>
              <li>
                We may at any time at our sole discretion reinstate suspended/terminated Users. A terminated User may not register or attempt to register with us or use the Kreeda Platform in any manner whatsoever until such time that such User is reinstated by us.

              </li>
            </ul>
          </ul>
          <br />
          <br />
          <div className="policy-item">
            <h2>
              7. INTELLECTUAL PROPERTY:
            </h2>
          </div>
          <ul className='alphabet-list' style={{ listStyleType: 'lower-alpha', marginLeft: 20 }}>
            <li>
              We are either the owner of intellectual property rights or have the non-exclusive, worldwide, perpetual, irrevocable, royalty free, sub-licensable (through multiple tiers) right to exercise the intellectual property, in the Kreeda Platform, and in the material published on it.
            </li>
            <li>
              You may print off one copy, and may download extracts, of any page(s), files or other content from the Kreeda Platform for your personal reference and you may draw the attention of others within your organisation to material available on the Kreeda Platform.
            </li>
            <li>
              You must not redistribute any material from the Kreeda Platform and where it has been specifically and expressly made evident by Kreeda Connect that certain content is specifically made available for redistribution, it may be redistributed only within your organization, and as intended by Kreeda Connect.
            </li>
            <li>
              You must not republish material from the Kreeda Platform on any other print or digital media platform, or display in material on the Kreeda Platform in public. You must also not republish or reproduce any material from the Kreeda Platform through the use of iframes or screenscrapers.
            </li>
            <li>
              You must not modify or edit any materials you have printed off or downloaded from the Kreeda Platform, and you must not use any illustrations, photographs, video or audio sequences or any graphics separately from any accompanying text.
            </li>
            <li>
              You must not use, reproduce, duplicate, copy, sell, sub-license or rent out any part of the materials on the Kreeda Platform for commercial purposes without obtaining a licence to do so from us or our licensors.
            </li>
            <li>
              If you print off, copy or download any part of the Kreeda Platform in breach of these Terms of Use, your right to use the Kreeda Platform will cease immediately and you must, at our option, return or destroy any copies of the materials you have made.
            </li>
          </ul>
        </section>
        <footer className="terms-footer">
          <p>&copy; {new Date().getFullYear()} Kreeda Connect. All rights reserved.</p>
        </footer>
      </div>
      <br />
      <Footer />
    </div>
  )
}

export default Termsandconditions