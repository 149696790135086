import React from 'react';

const AddReserveplayerModel = ({ show, onClose, onSubmit, player, setPlayer, teamPlayers }) => {
    if (!show) return null;

    const handleChange = (e) => {
        const { name, value } = e.target;
        setPlayer((prevPlayer) => ({ ...prevPlayer, [name]: value }));
    };


    return (
        <div className="modal-backdrop">
            <div className="modal-content">
                <h2>Player Details</h2>
                <input
                    type="text"
                    name="playerName"
                    placeholder="Player Name"
                    value={player?.playerName}
                    onChange={handleChange}
                />
                <select
                    value={player?.role}
                    onChange={handleChange}
                    name="role"
                >
                    <option value="None">None</option>
                    <option value="Batsman">Batsman</option>
                    <option value="Bowler">Bowler</option>
                    <option value="Wicket-Keeper">Wicket-Keeper</option>
                    <option value="All-Rounder">All-Rounder</option>
                </select>
                <select
                    value={player?.designation}
                    onChange={handleChange}
                    name="designation"
                >
                    <option value="None">Player</option>
                </select>
                <button className='button' onClick={onSubmit}>Submit</button>
                <button className='button' onClick={onClose}>Close</button>
            </div>
        </div>
    );
};

export default AddReserveplayerModel;
