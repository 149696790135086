import React, { useEffect, useState } from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import { useNavigate } from 'react-router-dom';
import { apiGet, Get_Api } from '../ApiUrls';
import img from '../Assets/Rectangle 21.svg'

function TurfownerAllTurfs() {

    const navigate = useNavigate()
    const [Allturfs, setAllturfs] = useState([]);
    const [IsUploading, setIsUploading] = useState(false);

    const [AllSports, setAllSports] = useState([]);
    const [Selectedsports, setSelectedsports] = useState('');

    const SelectSports = (event) => {
        setSelectedsports(event.target.value);
    };

    const GetAllSports = async () => {
        try {
            setIsUploading(true);
            const response = await Get_Api(`api/admin/GetAllSports`);
            setAllSports(response?.data);
            setIsUploading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const GetAllTurf = async () => {
        try {
            setIsUploading(true);
            const queryParams = new URLSearchParams();
            if (Selectedsports) queryParams.append("AvailableSports", Selectedsports);
            const response = await apiGet(`api/turf/GetAllTurfofowner?${queryParams.toString()}`);
            console.log(response.data);
            setAllturfs(response.data);
            setIsUploading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        GetAllSports();
        GetAllTurf();
    }, []);

    useEffect(() => {
        GetAllTurf();
    }, [Selectedsports]);

    const TurfDetailsdetails = (id) => {
        navigate(`/TurfOwnerTurfDetails/${id}`)
    }

    const [city, setcity] = useState('');

    const localcity = localStorage.getItem("city");

    useEffect(() => {
        setcity(localcity || 'Indore')
    }, []);


    return (
        <>
            <Navbar city={city} setcity={setcity} />
            <div className='reg-banner'>
                <div className='reg-banner-div'>
                    <h2>GET Rs 300<br />
                        <span>On 1st Time Registration</span></h2>
                    <br />
                </div>
            </div>
            <div className='available-venues'>
                <div className='available-venues-div'>
                    <div className='available-venues-heading'>
                        <h2>My Turfs  ({Allturfs?.length || 0})</h2>
                    </div>
                    <br />
                    <div className='filters'>
                        <div class="dropdown-container">
                            <select id="options" value={Selectedsports} onChange={SelectSports}>
                                <option value="">Select a Sport</option>
                                {AllSports?.map((sport) => (
                                    <option key={sport?._id} value={sport?._id}>
                                        {sport?.Name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <br />
                    <br />
                    <div className='game-cards'>
                        {Allturfs && Allturfs.length > 0 ? (
                            Allturfs?.map((turf, index) => (
                                <div key={index}>
                                    <div className='game-card'>
                                        <div style={{ backgroundImage: `url(${turf?.Images[0] || img})` }} className='game-card-img'>
                                        </div>
                                        <br />
                                        <div className='venue-title'>
                                            <h5>{turf?.Name?.slice(0, 30)}</h5>
                                        </div>
                                        <p>{turf?.Location}</p>
                                        <h4>Rs. {turf?.Price}/- Per Hour</h4>
                                        <button onClick={() => { TurfDetailsdetails(turf?._id) }}>Detailed View</button>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div className="no-institute-found">
                                <p>No Turf Found In this region And category</p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default TurfownerAllTurfs