import React, { useEffect, useState } from 'react';
import Navbar from '../../Components/Navbar';
import Footer from '../../Components/Footer';
import tabletennis from '../../Assets/tabletennis.svg'
import tennis from '../../Assets/tennis.svg'
import badminton from '../../Assets/badminton.svg'
import hockey from '../../Assets/hockey.svg'
import basketball from '../../Assets/basketball.svg'
import cricket from '../../Assets/cricketG.svg'
import football from '../../Assets/footballG.svg'
import { apiPost } from '../../ApiUrls';
import { toast } from 'react-toastify';
import { ClipLoader } from 'react-spinners';
import { useNavigate } from 'react-router-dom';
function CreateGame() {
    const navigate = useNavigate()
    const [activeTab, setActiveTab] = useState(1);

    const [IsUser, setIsUser] = useState(false);
    const [IsInstitute, setIsInstitute] = useState(false);
    const [IsTurfOwner, setIsTurfOwner] = useState(false);


    useEffect(() => {
        const userType = JSON.parse(localStorage.getItem("userType"));

        if (userType === "User") {
            setIsUser(true);
        }
        if (userType === "CoachingInstitute") {
            setIsInstitute(true);
        }
        if (userType === "TurfOwner") {
            setIsTurfOwner(true);
        }
    }, []);


    const handleTabChange = (tabIndex) => {
        setActiveTab(tabIndex);
    };

    const [selectGame, setselectGame] = useState('');

    const SelectedGame = (game) => {
        setselectGame(game)
    }

    const [Game, setGame] = useState('Cricket');
    const [GameName, setGameName] = useState();
    const [NumberofPlayer, setNumberofPlayer] = useState();
    const [OversMatch, setOversMatch] = useState();
    const [TeamAName, setTeamAName] = useState();
    const [TeamBName, setTeamBName] = useState();
    const [VannueName, setVannueName] = useState();
    const [MatchDate, setMatchDate] = useState();
    const [ReservePlayer, setReservePlayer] = useState();

    const [IsUploading, setIsUploading] = useState(false)

    const CreateGame = async () => {
        try {
            const token = localStorage.getItem("userToken");

            if (!token) {
                toast.error('Please Login First....');
                return
            }
            if (!GameName) return toast.error('Game Name is required');
            if (!NumberofPlayer) return toast.error('Number of Players is required');
            if (!OversMatch) return toast.error('Number of Overs is required');
            if (!TeamAName) return toast.error('Team A Name is required');
            if (!TeamBName) return toast.error('Team B Name is required');
            if (!VannueName) return toast.error('Venue Name is required');
            if (!MatchDate) return toast.error('Match Date is required');
            if (!ReservePlayer) return toast.error('Reserve Player information is required');

            let data = {
                Game: Game,
                GameName: GameName,
                NumberofPlayer: NumberofPlayer,
                OversMatch: OversMatch,
                TeamName: {
                    TeamAName: TeamAName,
                    TeamBName: TeamBName,
                },
                VannueName: VannueName,
                MatchDate: MatchDate,
                ReservePlayer: ReservePlayer,
            }
            setIsUploading(true);
            const response = await apiPost(`api/game/CreateGame`, data);
            console.log(response?.data)
            setGameName('')
            setNumberofPlayer('')
            setOversMatch('')
            setTeamAName('')
            setTeamBName('')
            setVannueName('')
            setMatchDate('')
            setReservePlayer('')
            setIsUploading(false);
            toast.success(response?.message)
            navigate(`/CreateTeam/${response?.data?._id}`)

        } catch (error) {
            toast.error(error?.response?.message)
            console.error('Error fetching data:', error);
        }
    }
    const CreateGameforTurfOnwer = async () => {
        try {
            const token = localStorage.getItem("userToken");

            if (!token) {
                toast.error('Please Login First....');
                return
            }
            if (!GameName) return toast.error('Game Name is required');
            if (!NumberofPlayer) return toast.error('Number of Players is required');
            if (!OversMatch) return toast.error('Number of Overs is required');
            if (!TeamAName) return toast.error('Team A Name is required');
            if (!TeamBName) return toast.error('Team B Name is required');
            if (!VannueName) return toast.error('Venue Name is required');
            if (!MatchDate) return toast.error('Match Date is required');
            if (!ReservePlayer) return toast.error('Reserve Player information is required');

            let data = {
                Game: Game,
                GameName: GameName,
                NumberofPlayer: NumberofPlayer,
                OversMatch: OversMatch,
                TeamName: {
                    TeamAName: TeamAName,
                    TeamBName: TeamBName,
                },
                VannueName: VannueName,
                MatchDate: MatchDate,
                ReservePlayer: ReservePlayer,
            }
            setIsUploading(true);
            const response = await apiPost(`api/game/CreateGameForTurfOwner`, data);
            console.log(response?.data)
            setGameName('')
            setNumberofPlayer('')
            setOversMatch('')
            setTeamAName('')
            setTeamBName('')
            setVannueName('')
            setMatchDate('')
            setReservePlayer('')
            setIsUploading(false);
            toast.success(response?.message)
            navigate(`/CreateTeam/${response?.data?._id}`)

        } catch (error) {
            toast.error(error?.response?.message)
            console.error('Error fetching data:', error);
        }
    }
    const CreateGameforInstituteOwner = async () => {
        try {
            const token = localStorage.getItem("userToken");

            if (!token) {
                toast.error('Please Login First....');
                return
            }
            if (!GameName) return toast.error('Game Name is required');
            if (!NumberofPlayer) return toast.error('Number of Players is required');
            if (!OversMatch) return toast.error('Number of Overs is required');
            if (!TeamAName) return toast.error('Team A Name is required');
            if (!TeamBName) return toast.error('Team B Name is required');
            if (!VannueName) return toast.error('Venue Name is required');
            if (!MatchDate) return toast.error('Match Date is required');
            if (!ReservePlayer) return toast.error('Reserve Player information is required');

            let data = {
                Game: Game,
                GameName: GameName,
                NumberofPlayer: NumberofPlayer,
                OversMatch: OversMatch,
                TeamName: {
                    TeamAName: TeamAName,
                    TeamBName: TeamBName,
                },
                VannueName: VannueName,
                MatchDate: MatchDate,
                ReservePlayer: ReservePlayer,
            }
            setIsUploading(true);
            const response = await apiPost(`api/game/CreateGameforInstituteOwner`, data);
            console.log(response?.data)
            setGameName('')
            setNumberofPlayer('')
            setOversMatch('')
            setTeamAName('')
            setTeamBName('')
            setVannueName('')
            setMatchDate('')
            setReservePlayer('')
            setIsUploading(false);
            toast.success(response?.message)
            navigate(`/CreateTeam/${response?.data?._id}`)

        } catch (error) {
            toast.error(error?.response?.message)
            console.error('Error fetching data:', error);
        }
    }

    const [city, setcity] = useState('');

    const localcity = localStorage.getItem("city");

    useEffect(() => {
        setcity(localcity || 'Indore')
    }, []);

    return (
        <>
            {
                IsUploading ?
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 500 }}>
                        <ClipLoader color="#31ED31" size={200} />
                    </div>
                    :
                    <>
                        <Navbar city={city} setcity={setcity} />

                        <div className='addturf-div'>
                            <div className="login-teb">
                                <button
                                    className={activeTab === 1 ? 'tab-btn active' : 'tab-btn'}
                                    onClick={() => handleTabChange(1)}
                                >
                                    Select Game
                                </button>
                                <button
                                    className={activeTab === 2 ? 'tab-btn active' : 'tab-btn'}
                                    onClick={() => handleTabChange(2)}
                                >
                                    Create Game
                                </button>
                            </div>
                            <br />
                            <br />
                            <br />
                            {activeTab === 1 &&
                                <div className='addturf'>
                                    <h1 style={{ textAlign: 'center', fontFamily: 'Oxanium', fontSize: '30px', margin: '20px 0' }}>Select Sport</h1>
                                    <div className='sports-container1'>
                                        <div className='sports-grid'>
                                            <button onClick={() => { SelectedGame('Cricket') }} className={`sport-btn ${selectGame === 'Cricket' ? 'active' : ''}`}>
                                                <img src={cricket} className='sport-icon' />
                                                <span>Cricket</span>
                                            </button>
                                            <button onClick={() => { SelectedGame('Football') }} className={`sport-btn ${selectGame === 'Football' ? 'active' : ''}`}>
                                                <img src={football} className='sport-icon' />
                                                <span>Football</span>
                                            </button>
                                            <button onClick={() => { SelectedGame('tabletennis') }} className={`sport-btn ${selectGame === 'tabletennis' ? 'active' : ''}`}>
                                                <img src={tabletennis} className='sport-icon' />
                                                <span>Table Tennis</span>
                                            </button>
                                            <button onClick={() => { SelectedGame('basketball') }} className={`sport-btn ${selectGame === 'basketball' ? 'active' : ''}`}>
                                                <img src={basketball} className='sport-icon' />
                                                <span>Basket Ball</span>
                                            </button>
                                            <button onClick={() => { SelectedGame('Hockey') }} className={`sport-btn ${selectGame === 'Hockey' ? 'active' : ''}`}>
                                                <img src={hockey} className='sport-icon' />
                                                <span>Hockey</span>
                                            </button>
                                            <button onClick={() => { SelectedGame('Badminton') }} className={`sport-btn ${selectGame === 'Badminton' ? 'active' : ''}`}>
                                                <img src={badminton} className='sport-icon' />
                                                <span>Badminton</span>
                                            </button>
                                            <button onClick={() => { SelectedGame('Tennis') }} className={`sport-btn ${selectGame === 'Tennis' ? 'active' : ''}`}>
                                                <img src={tennis} className='sport-icon' />
                                                <span>Tennis</span>
                                            </button>
                                        </div>
                                    </div>
                                    <br />
                                    <br />
                                    <div className='center-btn'>
                                        <button className='button' onClick={() => { handleTabChange(2) }}>Continue</button>
                                    </div>
                                </div>
                            }
                            {activeTab === 2 &&
                                <div className='addturf'>
                                    {selectGame === 'Cricket' ? (
                                        <>
                                            <h1> Create Game</h1>
                                            <br />
                                            <br />
                                            <div>
                                                <div className='addtruf-ips'>
                                                    <div className='addtruf-ip'>
                                                        <label>Game Name</label>
                                                        <input
                                                            placeholder='Game Name'
                                                            value={GameName}
                                                            onChange={(e) => setGameName(e.target.value)}
                                                        />
                                                    </div>
                                                    <div className='addtruf-ip'>
                                                        <label>No Of Players in Each Team</label>
                                                        <select
                                                            value={NumberofPlayer}
                                                            onChange={(e) => setNumberofPlayer(e.target.value)}
                                                        >
                                                            <option value="" disabled>Select</option>
                                                            <option value={1}>1</option>
                                                            <option value={2}>2</option>
                                                            <option value={3}>3</option>
                                                            <option value={4}>4</option>
                                                            <option value={5}>5</option>
                                                            <option value={6}>6</option>
                                                            <option value={7}>7</option>
                                                            <option value={8}>8</option>
                                                            <option value={9}>9</option>
                                                            <option value={10}>10</option>
                                                            <option value={11}>11</option>
                                                        </select>
                                                    </div>

                                                </div>
                                                <br />
                                                <div className='addtruf-ips'>
                                                    <div className='addtruf-ip'>
                                                        <label>Match Overs</label>
                                                        <input
                                                            placeholder='Match Overs'
                                                            value={OversMatch}
                                                            onChange={(e) => setOversMatch(e.target.value)}
                                                        />
                                                    </div>
                                                    <div className='addtruf-ip'>
                                                        <label>Team Name A</label>
                                                        <input
                                                            placeholder='Team Name A'
                                                            value={TeamAName}
                                                            onChange={(e) => setTeamAName(e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                                <br />
                                                <div className='addtruf-ips'>
                                                    <div className='addtruf-ip'>
                                                        <label>Team Name B</label>
                                                        <input
                                                            placeholder='Team Name B'
                                                            value={TeamBName}
                                                            onChange={(e) => setTeamBName(e.target.value)}
                                                        />
                                                    </div>
                                                    <div className='addtruf-ip'>
                                                        <label>Venue Name</label>
                                                        <input
                                                            placeholder='Venue Name'
                                                            value={VannueName}
                                                            onChange={(e) => setVannueName(e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                                <br />
                                                <div className='addtruf-ips'>
                                                    <div className='addtruf-ip'>
                                                        <label>Match Scheduled Date</label>
                                                        <input
                                                            placeholder='Match Scheduled Date'
                                                            type='date'
                                                            value={MatchDate}
                                                            onChange={(e) => setMatchDate(e.target.value)}
                                                        />
                                                    </div>
                                                    <div className='addtruf-ip'>
                                                        <label>Reserve Players in Each Team</label>
                                                        <select
                                                            value={ReservePlayer}
                                                            onChange={(e) => setReservePlayer(e.target.value)}
                                                        >
                                                            <option value="">Select</option>
                                                            <option value={0}>0</option>
                                                            <option value={1}>1</option>
                                                            <option value={2}>2</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <br />
                                                <br />
                                                <br />
                                                <br />
                                                <br />
                                                <div className='center-btn'>
                                                    {
                                                        !IsUser && !IsTurfOwner && !IsInstitute &&
                                                        <button className='button' onClick={() => { CreateGame() }}>Submit</button>
                                                    }
                                                    {
                                                        IsUser &&
                                                        <button className='button' onClick={() => { CreateGame() }}>Submit</button>
                                                    }
                                                    {
                                                        IsTurfOwner &&
                                                        <button className='button' onClick={() => { CreateGameforTurfOnwer() }}>Submit</button>
                                                    }
                                                    {
                                                        IsInstitute &&
                                                        <button className='button' onClick={() => { CreateGameforInstituteOwner() }}>Submit</button>
                                                    }

                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <div style={{ textAlign: 'center', fontSize: '24px', fontWeight: 'bold', marginTop: '50px', height: '200px' }}>
                                            <h1>
                                                Upcoming
                                            </h1>
                                        </div>
                                    )}

                                </div>
                            }
                        </div>
                        <Footer />

                    </>
            }
        </>
    )
}

export default CreateGame