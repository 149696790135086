import React, { useEffect, useState } from 'react';
import Navbar from '../../Components/Navbar'
import Footer from '../../Components/Footer'
import { apiPostFormData, Get_Api } from '../../ApiUrls';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import SelectLocation from '../../Components/SelectLocation';
import location from '../../Assets/location.svg';
function AddInstitute() {
    const navigate = useNavigate();
    const [selectedImages, setSelectedImages] = useState([]);
    const [Images, setImages] = useState([]);


    const handleImageChange = (e, index) => {
        const file = e.target.files[0];
        if (file) {
            setSelectedImages((prevImages) => {
                const updatedImages = [...prevImages];
                updatedImages[index] = file;
                return updatedImages;
            });
        }
    };



    const [Name, setName] = useState('');
    const [Location, setLocation] = useState('');
    const [Game, setGame] = useState('');
    const [InstituteDescription, setInstituteDescription] = useState('');
    const [startTime, setstartTime] = useState('');
    const [endTime, setendTime] = useState('');
    const [Experience, setExperience] = useState('');
    const [Features, setFeatures] = useState('');
    const [About, setAbout] = useState('');
    const [Services, setServices] = useState('');
    const [Achievements, setAchievements] = useState('');
    const [FaceBookLink, setFaceBookLink] = useState('');
    const [InstagramLink, setInstagramLink] = useState('');
    const [TwitterLink, setTwitterLink] = useState('');
    const [latitude, setlatitude] = useState();
    const [longitude, setlongitude] = useState();
    const [position, setPosition] = useState(null);


    const [IsUploading, setIsUploading] = useState(false);

    const CreateInstitute = async () => {
        try {
            const token = localStorage.getItem("userToken");

            if (!token) {
                toast.error('Please Login First....');
                return;
            }
            if (!Name) return toast.error('Name is required');
            if (!Location) return toast.error('Location is required');
            if (!Game) return toast.error('Please select Game');
            if (!InstituteDescription) return toast.error('InstituteDescription is required');
            if (!startTime) return toast.error('start Time is required');
            if (!endTime) return toast.error('end Time is required');
            if (!Experience) return toast.error('Experience is required');
            if (!About) return toast.error('About is required');
            if (!Services) return toast.error('Services is required');
            if (!Achievements) return toast.error('Achievements is required');
            if (!Features) return toast.error('Features is required');
            if (selectedImages.length === 0) return toast.error(' Images are required');
            setIsUploading(true);
            const formData = new FormData();
            formData.append('Name', Name);
            formData.append('Game', Game);
            formData.append('Location', Location);
            formData.append('latitude', latitude);
            formData.append('longitude', longitude);
            formData.append('InstituteDescription', InstituteDescription);
            formData.append('Experience', Experience);
            formData.append('About', About);
            formData.append('Services', Services);
            formData.append('Achievements', Achievements);
            formData.append('Services', Services);
            formData.append('Features', Features);
            formData.append('FaceBookLink', FaceBookLink);
            formData.append('InstagramLink', InstagramLink);
            formData.append('TwitterLink', TwitterLink);
            formData.append('Time', JSON.stringify({
                startTime,
                endTime
            }));
            selectedImages.forEach((image, index) => {
                formData.append(`Images`, image);
            });
            const response = await apiPostFormData('api/coach/CreateInstitute', formData);
            console.log(response);
            if (response?.statusCode === 200) {
                setName('');
                setGame('');
                setLocation('');
                setInstituteDescription('');
                setstartTime('');
                setendTime('');
                setExperience('');
                setAbout('');
                setServices('');
                setAchievements('');
                setFaceBookLink('');
                setInstagramLink('');
                setTwitterLink('');
                setFeatures('');
                setImages([]);
                setSelectedImages([]);
                setIsUploading(false);
                toast.success(response?.message || ' created successfully');
                navigate('/')
            }
            else {
                toast.error(response?.message || 'Institute  created successfully');
                setIsUploading(false);
            }
        } catch (error) {
            toast.error(error?.response?.message || 'Error creating Institute ');
            setIsUploading(false);
            console.error('Error creating Institute :', error);
        }
    };


    const [city, setcity] = useState('');

    const localcity = localStorage.getItem("city");

    useEffect(() => {
        setcity(localcity || 'Indore')
    }, []);

    const [cities, setcities] = useState([]);

    const [Sports, setSports] = useState([]);

    const getAllCity = async () => {
        try {
            setIsUploading(true);
            const response = await Get_Api(`api/admin/GetAllCity`);
            setcities(response.data);
        } catch (error) {
            toast.error('Please correct the Game ID');
            console.error('Error fetching data:', error);
        } finally {
            setIsUploading(false);
        }
    };
    const getAllSport = async () => {
        try {
            setIsUploading(true);
            const response = await Get_Api(`api/admin/GetAllSports`);
            setSports(response.data);
        } catch (error) {
            toast.error('Please correct the Game ID');
            console.error('Error fetching data:', error);
        } finally {
            setIsUploading(false);
        }
    };

    useEffect(() => {
        getAllCity();
        getAllSport();
    }, []);


    const handleSportsChange = (e) => {
        const selectedOptions = Array.from(e.target.selectedOptions, (option) => option.value);
        setGame(selectedOptions.join(', '));
    };

    const [selectLocation, setselectLocation] = useState(false);

    const OpenLocation = () => {
        setselectLocation(true)
    }

    const CloseLocation = () => {
        setselectLocation(false)
    }


    return (
        <>
            <Navbar city={city} setcity={setcity} />
            <div className='addturf-div'>
                <div className='addturf'>
                    <h1> Add Institute</h1>
                    <br />
                    <br />
                    <div>
                        <div className='addtruf-ips'>
                            <div className='addtruf-ip'>
                                <label>Name</label>
                                <input
                                    placeholder='Name'
                                    value={Name}
                                    onChange={(e) => { setName(e.target.value) }}
                                />
                            </div>
                        </div>
                        <br />
                        <div className='addtruf-ips'>
                            <div className='addtruf-ip'>
                                <label>Game</label>
                                <select
                                    value={Game}
                                    onChange={(e) => { setGame(e.target.value) }}
                                >
                                    {Sports?.map((sport, index) => (
                                        <option key={index} value={sport?.Name}>
                                            {sport?.Name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className='addtruf-ip'>
                                <label>Location</label>
                                <select value={Location} onChange={(e) => setLocation(e.target.value)}>
                                    <option value="Select a city" defaultValue >Select a city</option>
                                    {cities.map((city, index) => (
                                        <option key={index} value={city?.Name}>
                                            {city?.Name}
                                        </option>
                                    ))}
                                </select>
                            </div>

                        </div>
                        <br />

                        <div className='addtruf-ips'>
                            <div className='addtruf-ip'>
                                <label>Description</label>
                                <input
                                    placeholder='Institute Description'
                                    value={InstituteDescription}
                                    onChange={(e) => { setInstituteDescription(e.target.value) }}
                                />

                            </div>
                            <div className='addtruf-ip'>
                                <label>Experience</label>
                                <input
                                    placeholder='Experience'
                                    value={Experience}
                                    onChange={(e) => { setExperience(e.target.value) }}
                                />
                            </div>

                        </div>
                        <br />
                        <div className='addtruf-ips'>
                            <div className='addtruf-ip'>
                                <label>startTime</label>
                                <input
                                    placeholder='startTime'
                                    value={startTime}
                                    type='time'
                                    onChange={(e) => { setstartTime(e.target.value) }}
                                />

                            </div>
                            <div className='addtruf-ip'>
                                <label>endTime</label>
                                <input
                                    placeholder='endTime'
                                    value={endTime}
                                    type='time'
                                    onChange={(e) => { setendTime(e.target.value) }}
                                />
                            </div>
                        </div>
                        <br />
                        <div className='addtruf-ips'>
                            <div className='addtruf-ip'>
                                <label>About</label>
                                <input
                                    placeholder='About'
                                    value={About}
                                    onChange={(e) => { setAbout(e.target.value) }}
                                />

                            </div>
                            <div className='addtruf-ip'>
                                <label>Services</label>
                                <input
                                    placeholder='Services'
                                    value={Services}
                                    onChange={(e) => { setServices(e.target.value) }}
                                />
                            </div>
                        </div>
                        <br />
                        <div className="addtruf-ip">
                            <div
                                onClick={OpenLocation}
                                style={{ display: "flex", alignItems: "center", cursor: "pointer", gap: "8px" }}
                            >
                                <label style={{ cursor: "pointer" }}>Select a Location on the Map</label>
                                <img src={location} className="sport-icon" style={{ width: "20px", height: "20px", marginBottom: '12px' }} />
                            </div>
                            <div style={{ marginTop: "8px" }}>
                                <p>Latitude: {latitude}</p>
                                <p>Longitude: {longitude}</p>
                            </div>
                        </div>
                        <br />
                        <div className='addtruf-ips'>
                            <div className='addtruf-ip'>
                                <label>Institute  Images</label>
                                <div style={{ display: "flex", gap: "8px", flexWrap: "wrap" }}>
                                    {selectedImages?.map((image, index) => (
                                        <div
                                            key={index}
                                            style={{
                                                border: "1px solid #ccc",
                                                padding: "8px",
                                                width: "80px",
                                                height: "80px",
                                                position: "relative",
                                                borderRadius: "8px",
                                                overflow: "hidden",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <input
                                                type="file"
                                                accept="image/*"
                                                onChange={(e) => handleImageChange(e, index)}
                                                style={{
                                                    position: "absolute",
                                                    width: "100%",
                                                    height: "100%",
                                                    opacity: 0,
                                                    cursor: "pointer",
                                                }}
                                            />
                                            {image ? (
                                                <img
                                                    src={URL.createObjectURL(image)}
                                                    alt={`Selected ${index + 1}`}
                                                    style={{
                                                        width: "100%",
                                                        height: "100%",
                                                        objectFit: "cover",
                                                    }}
                                                />
                                            ) : (
                                                <p
                                                    style={{
                                                        textAlign: "center",
                                                        color: "#aaa",
                                                        fontSize: "14px",
                                                    }}
                                                >
                                                    Click to select
                                                </p>
                                            )}
                                        </div>
                                    ))}
                                    <div
                                        style={{
                                            border: "1px dashed #aaa",
                                            padding: "8px",
                                            width: "80px",
                                            height: "80px",
                                            position: "relative",
                                            borderRadius: "8px",
                                            overflow: "hidden",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            cursor: "pointer",
                                        }}
                                        onClick={() => setSelectedImages([...selectedImages, null])}
                                    >
                                        <p style={{ textAlign: "center", color: "#aaa", fontSize: "14px" }}>
                                            +
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='addtruf-ip'>
                                <label>Achievements</label>
                                <input
                                    placeholder='Achievements'
                                    value={Achievements}
                                    onChange={(e) => { setAchievements(e.target.value) }}
                                />
                            </div>
                        </div>
                        <br />
                        <div className='addtruf-ips'>
                            <div className='addtruf-ip'>
                                <label>Feature</label>
                                <input
                                    placeholder='Features'
                                    value={Features}
                                    onChange={(e) => { setFeatures(e.target.value) }}
                                />
                            </div>
                            <div className='addtruf-ip'>
                                <label>TwitterLink</label>
                                <input
                                    placeholder='TwitterLink'
                                    value={TwitterLink}
                                    onChange={(e) => { setTwitterLink(e.target.value) }}
                                />
                            </div>
                        </div>
                        <br />
                        <div className='addtruf-ips'>
                            <div className='addtruf-ip'>
                                <label>Instagram Link</label>
                                <input
                                    placeholder='InstagramLink'
                                    value={InstagramLink}
                                    onChange={(e) => { setInstagramLink(e.target.value) }}
                                />

                            </div>
                            <div className='addtruf-ip'>
                                <label>FaceBook Link</label>
                                <input
                                    placeholder='FaceBookLink'
                                    value={FaceBookLink}
                                    onChange={(e) => { setFaceBookLink(e.target.value) }}
                                />
                            </div>
                        </div>
                        <br />
                        <br />
                        <div className='center-btn'>
                            {IsUploading ? (
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 50 }}>
                                    <ClipLoader color="#31ED31" size={50} />
                                </div>
                            ) : (
                                <button className='button' onClick={() => { CreateInstitute() }}>Submit</button>
                            )}
                        </div>
                    </div>

                </div>
            </div>
            <SelectLocation
                isOpen={selectLocation}
                setIsOpen={setselectLocation}
                latitude={latitude}
                longitude={longitude}
                position={position}
                setlatitude={setlatitude}
                setlongitude={setlongitude}
                setPosition={setPosition}
            />
            <Footer />

        </>
    )
}

export default AddInstitute