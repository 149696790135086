import React, { useEffect, useState } from 'react';
import Navbar from '../../Components/Navbar'
import Footer from '../../Components/Footer'
import img from '../../Assets/image 4.png'
import { Link, useNavigate } from 'react-router-dom';
import { Create_Api } from '../../ApiUrls';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ClipLoader } from 'react-spinners';
import { Eye, EyeOff } from 'lucide-react';


function UserSignup() {
    const navigate = useNavigate()
    const [IsUploading, setIsUploading] = useState(false);
    const [Name, setName] = useState('');
    const [Email, setEmail] = useState('');
    const [phoneNumber, setphoneNumber] = useState('');
    const [Password, setPassword] = useState('');
    const [ConfirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const [Otp, setOtp] = useState('');

    const SignUp = async () => {
        if (!Name || !Email || !phoneNumber || !Password || !ConfirmPassword) {
            toast.error('All fields are required!');
            return;
        }
        if (phoneNumber.length !== 10) {
            toast.error('Please Enter Correct Phone Number');
            return;
        }
        console.log(ConfirmPassword)
        console.log(Password)
        if (Password !== ConfirmPassword) {
            toast.error('Password and Confirm Password must match!');
            return;
        }
        try {
            setIsUploading(true)
            let data = {
                Name,
                Email,
                phoneNumber: '91' + phoneNumber,
                Password
            }
            const response = await Create_Api(`api/user/register`, data)
            console.log('User register Successfull:', response);
            localStorage.setItem('user', JSON.stringify(response?.data));
            localStorage.setItem('userType', JSON.stringify('User'));
            localStorage.setItem('userid', response?.data?.User?._id);
            localStorage.setItem('userToken', response?.data?.token);
            toast.success(response?.message);
            setName('')
            setEmail('')
            setphoneNumber('')
            setPassword('')
            setConfirmPassword('')
            navigate('/')
            setIsUploading(false)
        } catch (error) {
            toast.success(error?.response?.data?.message);
            setIsUploading(false)
            console.log(error)
        }
    }

    const [city, setcity] = useState('');

    const localcity = localStorage.getItem("city");

    useEffect(() => {
        setcity(localcity || 'Indore')
    }, []);


    const [ShowOtp, setShowOtp] = useState(false);

    const SignUpSendOtp = async () => {
        if (!Name || !Email || !phoneNumber || !Password || !ConfirmPassword) {
            toast.error('All fields are required!');
            return;
        }
        if (phoneNumber.length !== 10) {
            toast.error('Please Enter Correct Phone Number');
            return;
        }
        console.log(Password)
        if (Password !== ConfirmPassword) {
            toast.error('Password and Confirm Password must match!');
            return;
        }
        try {
            setIsUploading(true)
            let data = {
                phoneNumber: '91' + phoneNumber,
            }
            const response = await Create_Api(`api/user/UserSendOtpNumber`, data)
            toast.success(response?.message);
            setShowOtp(true)
            setIsUploading(false)
        } catch (error) {
            toast.success(error?.response?.data?.message);
            setIsUploading(false)
            console.log(error)
        }
    }

    const SignUpOtpVerification = async () => {
        try {
            setIsUploading(true)
            let data = {
                phoneNumber: '91' + phoneNumber,
                otp: Otp
            }
            const response = await Create_Api(`api/user/UserVerifyUpdateNumberOtp`, data)
            if (response.data === true) {
                console.log('User register Successfull:', response);
                toast.success(response?.message);
                await SignUp()
            }

            setIsUploading(false)
        } catch (error) {
            toast.success(error?.response?.data?.message);
            setIsUploading(false)
            console.log(error)
        }
    }





    return (
        <>
            <Navbar city={city} setcity={setcity} />
            <div className='login'>
                <div className="login-tebs">
                    <br />
                    <br />
                </div>
                <div className="tab-content">
                    <div className='login-section'>
                        <div className='login-left'>
                            <img src={img} />
                        </div>
                        <div className='login-right'>
                            <div className='login-right-div'>
                                <h3>Sign Up</h3>
                                <br />
                                <br />
                                <br />
                                <label>Name </label>
                                <br />
                                <input
                                    placeholder='Name'
                                    value={Name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                                <br />
                                <label>Email </label>
                                <br />
                                <input
                                    placeholder='Email '
                                    type='email'
                                    value={Email}
                                    onChange={(e) => { setEmail(e.target.value) }}
                                />
                                <br />
                                <label>Phone Number </label>
                                <br />
                                <input
                                    type='number'
                                    placeholder=' Phone Number'
                                    className="no-spinner"
                                    value={phoneNumber}
                                    onChange={(e) => { setphoneNumber(e.target.value) }}
                                />
                                <br />
                                {/* <label>Password </label>
                                <br />
                                <input
                                    placeholder='Enter Password'
                                    type='password'
                                    value={Password}
                                    onChange={(e) => { setPassword(e.target.value) }}
                                /> */}
                                <div style={{ position: 'relative', width: '100%' }}>
                                    <label>Password</label>
                                    <br />
                                    <input
                                        placeholder="Enter Password"
                                        type={showPassword ? 'text' : 'password'}
                                        value={Password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        style={{ paddingRight: '40px', width: '100%' }} // Add space for the icon
                                    />
                                    <span
                                        onClick={() => setShowPassword(!showPassword)}
                                        style={{
                                            position: 'absolute',
                                            right: '10px',
                                            top: '62%',
                                            transform: 'translateY(-50%)',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
                                    </span>
                                </div>
                                <br />
                                <div style={{ position: 'relative', width: '100%' }}>
                                    <label>Confirm Password </label>
                                    <br />
                                    <input
                                        placeholder='Confirm Password'
                                        type={showPassword ? 'text' : 'password'}
                                        value={ConfirmPassword}
                                        onChange={(e) => { setConfirmPassword(e.target.value) }}
                                    />
                                    <span
                                        onClick={() => setShowPassword(!showPassword)}
                                        style={{
                                            position: 'absolute',
                                            right: '10px',
                                            top: '62%',
                                            transform: 'translateY(-50%)',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
                                    </span>
                                </div>
                                <br />
                                {ShowOtp &&
                                    <>
                                        <label>Enter Otp </label>
                                        <br />
                                        <input
                                            placeholder='Otp'
                                            type='number'
                                            value={Otp}
                                            onChange={(e) => { setOtp(e.target.value) }}
                                            className="no-spinner"
                                        />
                                        <br />
                                    </>
                                }
                                {
                                    !ShowOtp && (
                                        IsUploading ? (
                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 50 }}>
                                                <ClipLoader color="#31ED31" size={50} />
                                            </div>
                                        ) : (
                                            <button className="tab-btn active" onClick={SignUpSendOtp}>
                                                Sign Up
                                            </button>
                                        )
                                    )
                                }
                                {
                                    ShowOtp && (
                                        IsUploading ? (
                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 50 }}>
                                                <ClipLoader color="#31ED31" size={50} />
                                            </div>
                                        ) : (
                                            <button className="tab-btn active" onClick={SignUpOtpVerification}>
                                                Sign Up
                                            </button>
                                        )
                                    )
                                }

                                <br />
                                <br />
                                <p>Already Have an Account?</p>
                                <br />
                                <Link style={{ textDecoration: 'none' }} to='/login'>
                                    <h4>Log In ?</h4>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>

            </div >
            <Footer />
        </>
    )
}

export default UserSignup