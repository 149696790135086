import React, { useEffect, useState } from 'react'
import Navbar from '../Components/Navbar'
import Banner from '../Components/Banner'
import cat1 from '../Assets/soccer-ball-green-grass-soccer-field-generative-ai 1.svg'
import star from '../Assets/Sub Container.svg'
import profile from '../Assets/Profile.svg'
import man from '../Assets/Group 1000005979.png'
import Footer from '../Components/Footer'
import { Get_Api } from '../ApiUrls'
import img from '../Assets/Rectangle 21.svg'
import { useNavigate } from 'react-router-dom'
function Home() {
        const navigate = useNavigate()
        const [Allturfs, setAllturfs] = useState([]);
        const [AllInstitute, setAllInstitute] = useState([]);
        const [AllReview, setAllReview] = useState([]);
        const [IsUploading, setIsUploading] = useState(false);
        const [AllSports, setAllSports] = useState([]);
        const [Selectedsports, setSelectedsports] = useState('');

        const SelectSports = (data) => {
                setSelectedsports(data)
        }

        const [city, setcity] = useState('');

        const localcity = localStorage.getItem("city");

        useEffect(() => {
                setcity(localcity || 'Indore')
        }, []);

        const GetAllTurf = async () => {
                try {
                        setIsUploading(true);
                        const queryParams = new URLSearchParams();
                        if (Selectedsports) queryParams.append("AvailableSports", Selectedsports);

                        const response = await Get_Api(`api/user/GetAllTurf?location=${city}&${queryParams.toString()}`);
                        setAllturfs(response?.data);
                        setIsUploading(false);
                } catch (error) {
                        console.error('Error fetching data:', error);
                }
        };


        const GetAllInstitute = async () => {
                try {
                        setIsUploading(true);
                        const response = await Get_Api(`api/user/GetAllInstitute?location=${city}`);
                        setAllInstitute(response?.data);
                        setIsUploading(false);
                } catch (error) {
                        console.error('Error fetching data:', error);
                }
        };

        const GetAllreview = async () => {
                try {
                        setIsUploading(true);
                        const response = await Get_Api(`api/admin/getAllReviews`);
                        setAllReview(response.data);
                        setIsUploading(false);
                } catch (error) {
                        console.error('Error fetching data:', error);
                }
        };

        const GetAllSports = async () => {
                try {
                        setIsUploading(true);
                        const response = await Get_Api(`api/admin/GetAllSports`);
                        setAllSports(response?.data);
                        setIsUploading(false);
                } catch (error) {
                        console.error('Error fetching data:', error);
                }
        };

        useEffect(() => {
                if (city !== '') {
                        GetAllTurf();
                        GetAllInstitute();
                        GetAllreview();
                        GetAllSports();
                }
        }, [city]);

        useEffect(() => {
                if (city !== '') {
                        GetAllTurf();
                }
        }, [city, Selectedsports]);

        const institutedetails = (id) => {
                navigate(`/coachdetail/${id}`)
        }
        const TurfDetailsdetails = (id) => {
                navigate(`/TurfDetails/${id}`)
        }
        const Creategamenavigate = () => {
                navigate('/CreateGame')
        }


        return (
                <>
                        <Navbar city={city} setcity={setcity} />
                        <Banner Allturfs={Allturfs} />
                        <div className='cat-div'>
                                <div className='cat-section'>
                                        <div className='cat-heading'>
                                                <p>Sports</p>
                                                <button onClick={() => { Creategamenavigate() }}>Create Game +</button>
                                        </div>
                                        <br />
                                        <br />
                                        <div className='car-cards'>
                                                {AllSports && AllSports?.length > 0 ? (
                                                        AllSports?.map((sports, index) => (
                                                                <div className={`car-card ${Selectedsports === sports?._id ? 'CardActive' : ''}`}
                                                                        onClick={() => SelectSports(sports?._id)}>
                                                                        <div key={index} className='car-card-heading'>
                                                                                <p>{sports?.Name || "Cricket"}</p>
                                                                        </div>
                                                                        <img src={sports?.Images||cat1} />
                                                                </div>
                                                        ))
                                                ) : (
                                                        <div className='no-institute-found'>
                                                                <p>No Sports found</p>
                                                        </div>
                                                )}
                                                {/* <div className='car-card'>
                                                        <div className='car-card-heading'>
                                                                <p>Football</p>
                                                        </div>
                                                        <img src={cat1} />
                                                </div>
                                                <div className='car-card'>
                                                        <img src={cat1} />
                                                        <div className='car-card-heading'>
                                                                <p>Football</p>
                                                        </div>
                                                </div>
                                                <div className='car-card'>
                                                        <div className='car-card-heading'>
                                                                <p>Football</p>
                                                        </div>
                                                        <img src={cat1} />
                                                </div>
                                                <div className='car-card'>
                                                        <img src={cat1} />
                                                        <div className='car-card-heading'>
                                                                <p>Football</p>
                                                        </div>
                                                </div> */}
                                        </div>
                                        <br />
                                        <br />


                                        <div className='game-cards-div'>
                                                <div className='game-cards-heading'>
                                                        <h3>Venues / Ground</h3>
                                                        <p onClick={() => { navigate('/Turfvanues') }}>See all</p>
                                                </div>
                                                <br />
                                                <br />
                                                <br />
                                                <br />
                                                <div className='game-cards'>
                                                        {Allturfs && Allturfs?.length > 0 ? (
                                                                Allturfs?.map((turf, index) => (
                                                                        <div key={index}>
                                                                                <div className='game-card'>
                                                                                        <div
                                                                                                style={{ backgroundImage: `url(${turf?.Images[0] || img})` }}
                                                                                                className='game-card-img'
                                                                                        ></div>
                                                                                        <br />
                                                                                        <h3>{turf?.Name}</h3>
                                                                                        <p>{turf?.Location} {turf?.Dimension}</p>
                                                                                        <h4>Rs.{turf?.Price} per Slot</h4>
                                                                                        <button onClick={() => { TurfDetailsdetails(turf?._id) }}>Book Now</button>
                                                                                </div>
                                                                        </div>
                                                                ))
                                                        ) : (
                                                                <div className='no-institute-found'>
                                                                        <p>No Turf Found In this region And category</p>
                                                                </div>
                                                        )}
                                                </div>

                                        </div>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <div className='game-cards-div'>
                                                <div className='game-cards-heading'>
                                                        <h3>Coaching Institutions</h3>
                                                        <p onClick={() => { navigate('/Institutes') }}>See all</p>
                                                </div>
                                                <br />
                                                <br />
                                                <div className='game-cards'>
                                                        {AllInstitute && AllInstitute.length > 0 ? (
                                                                AllInstitute.map((institute, index) => (
                                                                        <div key={index}>
                                                                                <div className='game-card'>
                                                                                        <div
                                                                                                style={{ backgroundImage: `url(${institute?.profileimage || img})` }}
                                                                                                className='game-card-img'
                                                                                        ></div>
                                                                                        <br />
                                                                                        <h3>{institute?.Name}</h3>
                                                                                        <p>{institute?.Location}</p>
                                                                                        <h4>Rs.1080 / 3 months</h4>
                                                                                        <button onClick={() => institutedetails(institute?._id)}>Book Now</button>
                                                                                </div>
                                                                        </div>
                                                                ))
                                                        ) : (
                                                                <div className='no-institute-found'>
                                                                        <p>No Institute Found In this region</p>
                                                                </div>
                                                        )}
                                                </div>

                                        </div>
                                        <br />
                                        <br />
                                        <div className='reviews'>
                                                <h2>Customer Reviews</h2>
                                                <br />
                                                <br />
                                                <div className='review-cards'>
                                                        {AllReview?.map((Review, index) => (
                                                                <div key={index} className='review-card'>
                                                                        <div className='star'>
                                                                                {[...Array(Math.floor(Review?.Rating || 0))]?.map((_, index) => (
                                                                                        <img key={index} src={star} alt="star" />
                                                                                ))}
                                                                        </div>
                                                                        <br />
                                                                        <h3>{Review?.Turf?.Name}!</h3>
                                                                        <br />
                                                                        <p>{Review?.Review || "Our experience with Estatein was outstanding. Their team's dedication and professionalism made finding our dream home a breeze. Highly recommended!"}</p>
                                                                        <br />
                                                                        <div className='reviw-profile-div'>
                                                                                <img src={profile} />
                                                                                <div>
                                                                                        <p style={{ color: 'black' }}>{Review?.User?.Name}</p>
                                                                                        <p style={{ color: 'black' }}>{Review?.User?.Email}</p>
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                        ))}
                                                </div>
                                        </div>

                                </div>

                        </div>
                        <div className='resister'>
                                <div className='resister-div'>
                                        <div className='resister-div-left'>
                                                <h2>Register Your<br /> Sports Venue</h2>
                                                <br />
                                                <button onClick={() => { navigate('/TurfOwner/login') }}>Register Today</button>

                                        </div>
                                        <div className='resister-div-right'>
                                                <img src={man} />
                                        </div>
                                </div>
                        </div>
                        <Footer />
                </>
        )
}
export default Home