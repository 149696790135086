import React, { useState } from 'react';
import { apiPut, Create_Api } from '../ApiUrls';
import { toast } from 'react-toastify';
import { ClipLoader } from 'react-spinners';

const UpdateTurfOwnerNumber = ({ show, onClose, phoneNumber, setphoneNumber, GetTurfownerprofile }) => {
    const [IsUploading, setIsUploading] = useState(false);
    const [Otp, setOtp] = useState('');
    const [TurfShowOtp, setTurfShowOtp] = useState(false);

    const TurfOwnerUpdateSendOtp = async () => {
        if (phoneNumber.length !== 12) {
            toast.error('Please Enter Correct Phone Number');
            return;
        }
        try {
            setIsUploading(true)
            let data = {
                Number: phoneNumber,
            }
            const response = await Create_Api(`api/turf/TurfUpdateNumberSendOtp`, data)
            toast.success(response?.message);
            setTurfShowOtp(true)
            setIsUploading(false)
        } catch (error) {
            toast.success(error?.response?.data?.message);
            setIsUploading(false)
            console.log(error)
        }
    }

    const TurfOwnerUpdateOtpVerification = async () => {
        try {
            if (Otp.length !== 6) {
                toast.error('Please Enter Correct Phone Number');
                return;
            }
            setIsUploading(true)
            let data = {
                Number: phoneNumber,
                otp: Otp
            }
            const response = await Create_Api(`api/turf/TurfUpdateVerifyOtp`, data)
            if (response.data === true) {
                toast.success(response?.message);
                await UpdateTurfOwnerNumberapi()
            }

            setIsUploading(false)
        } catch (error) {
            toast.success(error?.response?.data?.message);
            setIsUploading(false)
            console.log(error)
        }
    }


    const UpdateTurfOwnerNumberapi = async () => {
        try {
            const token = localStorage.getItem("userToken");

            if (!token) {
                toast.error('Please Login First....');
                return
            }

            let data = {
                Number: phoneNumber
            }
            setIsUploading(true);
            const response = await apiPut(`api/turf/UpdateTurfOwner`, data);
            console.log(response?.data)
            setIsUploading(false);
            toast.success(response?.message)
            GetTurfownerprofile();
            onClose();
        } catch (error) {
            toast.error(error?.response?.message)
            console.error('Error fetching data:', error);
        }
    }


    if (!show) return null;
    return (
        <div className="modal-backdrop">
            <div className="modal-content">
                <h2>Turf Owner Update</h2>
                <br />
                <br />
                <div className='addtruf-ips'>
                    <div className='addtruf-ip'>
                        <label>Number</label>
                        <input
                            placeholder='Number'
                            value={phoneNumber}
                            onChange={(e) => { setphoneNumber(e.target.value) }}
                        />
                        {TurfShowOtp &&
                            <>
                                <label>Enter Otp </label>
                                <input
                                    placeholder='Otp'
                                    type='number'
                                    value={Otp}
                                    onChange={(e) => { setOtp(e.target.value) }}
                                    className="no-spinner"
                                />
                                <br />
                            </>
                        }
                    </div>
                </div>
                <br />
                {
                    !TurfShowOtp && (
                        IsUploading ? (
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 50 }}>
                                <ClipLoader color="#31ED31" size={50} />
                            </div>
                        ) : (
                            <button className="tab-btn active button" onClick={TurfOwnerUpdateSendOtp}>
                                Submit
                            </button>
                        )
                    )
                }
                {
                    TurfShowOtp && (
                        IsUploading ? (
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 50 }}>
                                <ClipLoader color="#31ED31" size={50} />
                            </div>
                        ) : (
                            <button className="tab-btn active button" onClick={TurfOwnerUpdateOtpVerification}>
                                Submit
                            </button>
                        )
                    )
                }

                <button className='button' onClick={() => { onClose() }}>Close</button>
            </div>
        </div>
    );
};

export default UpdateTurfOwnerNumber;
