import React, { useEffect, useState } from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import { useNavigate } from 'react-router-dom';
import { Get_Api } from '../ApiUrls';
import img from '../Assets/Rectangle 21.svg'

function Turfvanues() {

    const navigate = useNavigate()
    const [Allturfs, setAllturfs] = useState([]);
    const [IsUploading, setIsUploading] = useState(false);
    const [city, setcity] = useState('');

    const [AllSports, setAllSports] = useState([]);
    const [Selectedsports, setSelectedsports] = useState('');

    const SelectSports = (event) => {
        setSelectedsports(event.target.value);
    };

    const GetAllSports = async () => {
        try {
            setIsUploading(true);
            const response = await Get_Api(`api/admin/GetAllSports`);
            setAllSports(response?.data);
            setIsUploading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const localcity = localStorage.getItem("city");

    useEffect(() => {
        setcity(localcity || 'Indore')
    }, []);


    const GetAllTurf = async () => {
        try {
            setIsUploading(true);
            const queryParams = new URLSearchParams();
            if (Selectedsports) queryParams.append("AvailableSports", Selectedsports);
            const response = await Get_Api(`api/user/GetAllTurf?location=${city}&${queryParams.toString()}`);
            setAllturfs(response.data);
            setIsUploading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };


    useEffect(() => {
        if (city !== '') {
            GetAllSports();
            GetAllTurf();
        }
    }, [city]);

    useEffect(() => {
        if (city !== '') {
            GetAllTurf();
        }
    }, [city, Selectedsports]);



    const TurfDetailsdetails = (id) => {
        navigate(`/TurfDetails/${id}`)
    }


    return (
        <>
            <Navbar city={city} setcity={setcity} />
            <div className='reg-banner'>
                <div className='reg-banner-div'>
                    <h2>GET Rs 300<br />
                        <span>On 1st Time Registration</span></h2>
                    <br />
                    <button>Register Now</button>
                </div>
            </div>
            <div className='available-venues'>
                <div className='available-venues-div'>
                    <div className='available-venues-heading'>
                        <h2>Available venues ({Allturfs?.length || 0})</h2>
                    </div>
                    <br />
                    <div className='filters'>
                        <div class="dropdown-container">
                            <select id="options" value={Selectedsports} onChange={SelectSports}>
                                <option value="">Select a Sport</option>
                                {AllSports?.map((sport) => (
                                    <option key={sport?._id} value={sport?._id}>
                                        {sport?.Name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <br />
                    <br />
                    <div className='game-cards'>
                        {Allturfs && Allturfs?.length > 0 ? (
                            Allturfs?.map((turf, index) => (
                                <div key={index}>
                                    <div className='game-card'>
                                        <div style={{ backgroundImage: `url(${turf?.Images[0] || img})` }} className='game-card-img'>
                                        </div>
                                        <br />
                                        <div className='venue-title'>
                                            <h5>{turf?.Name?.slice(0, 30)}</h5>
                                        </div>
                                        <p>{turf?.Location}</p>
                                        <h4>Rs. {turf?.Price}/- Per Hour</h4>
                                        <button onClick={() => { TurfDetailsdetails(turf?._id) }}>Book Now</button>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div className='no-institute-found'>
                                <p>No Turf Found In this region And category</p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Turfvanues