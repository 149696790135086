import React, { useEffect, useState } from 'react';

import Navbar from '../../Components/Navbar'
import Footer from '../../Components/Footer'
import img from '../../Assets/image 4.png'
import { Link, useNavigate } from 'react-router-dom';
import { Create_Api } from '../../ApiUrls';
import { toast } from 'react-toastify';
import { ClipLoader } from 'react-spinners';
import { Eye, EyeOff } from 'lucide-react';

function UserLogin() {
    const navigate = useNavigate()
    const [IsUploading, setIsUploading] = useState(false);
    const [Email, setEmail] = useState('');
    const [Password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);


    const [LoginType, setLoginType] = useState('Email');

    const ChangeLogintab = (type) => {
        setLoginType(type)
    }

    const Login = async () => {
        if (!Email || !Password) {
            toast.error('Email and Password are required!');
            return;
        }
        try {
            let data = {
                Email,
                Password
            }
            setIsUploading(true)
            const response = await Create_Api(`api/user/Login`, data)
            console.log('User Login Successfull:', response);
            localStorage.setItem('user', JSON.stringify(response?.data));
            localStorage.setItem('userType', JSON.stringify('User'));
            localStorage.setItem('userid', response?.data?.User?._id);
            localStorage.setItem('userToken', response?.data?.token);
            toast.success(response?.message);
            setEmail('')
            setPassword('')
            navigate('/')
            setIsUploading(false)
        } catch (error) {
            setIsUploading(false)
            toast.error(error?.response?.data?.message);
            console.log(error)
        }
    }

    const [city, setcity] = useState('');

    const localcity = localStorage.getItem("city");

    useEffect(() => {
        setcity(localcity || 'Indore')
    }, []);


    const [phoneNumber, setphoneNumber] = useState('');
    const [otp, setotp] = useState('');
    const [Showotp, setShowotp] = useState(false);
    const [resendCountdown, setResendCountdown] = useState(0);

    const startResendCountdown = () => {
        setResendCountdown(30);
    };

    useEffect(() => {
        let timer;
        if (resendCountdown > 0) {
            timer = setTimeout(() => setResendCountdown(resendCountdown - 1), 1000);
        }
        return () => clearTimeout(timer);
    }, [resendCountdown]);


    const SendOtp = async () => {
        if (!phoneNumber) {
            toast.error('phoneNumber are required!');
            return;
        }
        try {
            let data = {
                phoneNumber: '91' + phoneNumber
            }
            setIsUploading(true)
            const response = await Create_Api(`api/user/LoginUsingNumber`, data)
            console.log('send Otp Successfull:', response);
            toast.success(response?.message);
            setShowotp(true)
            setIsUploading(false)
            startResendCountdown();
        } catch (error) {
            setIsUploading(false)
            toast.error(error?.response?.data?.message);
            console.log(error)
        }
    }

    const ResendOtp = async () => {
        SendOtp();
    };

    const VerifyOtp = async () => {
        if (!phoneNumber || !otp) {
            toast.error('phoneNumber and otp are required!');
            return;
        }
        try {
            let data = {
                phoneNumber: '91' + phoneNumber,
                otp
            }
            setIsUploading(true)
            const response = await Create_Api(`api/user/VerifyUserOtp`, data)
            console.log('User Login Successfull:', response);
            localStorage.setItem('user', JSON.stringify(response?.data));
            localStorage.setItem('userType', JSON.stringify('User'));
            localStorage.setItem('userid', response?.data?.User?._id);
            localStorage.setItem('userToken', response?.data?.token);
            toast.success(response?.message);
            setphoneNumber('')
            setotp('')
            navigate('/')
            setIsUploading(false)
        } catch (error) {
            setIsUploading(false)
            toast.error(error?.response?.data?.message);
            console.log(error)
        }
    }

    return (
        <>
            <Navbar city={city} setcity={setcity} />
            <div className='login'>
                <div className="login-tebs">
                    <br />
                    <br />
                    <div className="login-tebs">
                        <div className="login-teb">
                            <button
                                className={LoginType === "Email" ? 'tab-btn active' : 'tab-btn'}
                                onClick={() => ChangeLogintab("Email")}
                            >
                                Email
                            </button>
                            <button
                                className={LoginType === "Number" ? 'tab-btn active' : 'tab-btn'}
                                onClick={() => ChangeLogintab("Number")}
                            >
                                Number
                            </button>
                        </div>
                        <br />
                        <br />
                    </div>
                </div>
                <div className="tab-content">
                    <div className='login-section'>
                        <div className='login-left'>
                            <img src={img} />
                        </div>
                        <div className='login-right'>
                            <div className='login-right-div'>
                                <h3>Login</h3>
                                <br />
                                <br />
                                {
                                    LoginType === "Email" &&
                                    <>
                                        <label>Email </label>
                                        <br />
                                        <input
                                            placeholder='Email'
                                            value={Email}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                        <br />
                                        <div style={{ position: 'relative', width: '100%' }}>
                                            <label>Password </label>
                                            <br />
                                            <input
                                                placeholder='Enter Password'
                                                type={showPassword ? 'text' : 'password'}
                                                value={Password}
                                                onChange={(e) => setPassword(e.target.value)}
                                            />
                                            <span
                                                onClick={() => setShowPassword(!showPassword)}
                                                style={{
                                                    position: 'absolute',
                                                    right: '10px',
                                                    top: '62%',
                                                    transform: 'translateY(-50%)',
                                                    cursor: 'pointer',
                                                }}
                                            >
                                                {showPassword ? <Eye size={20} /> :  <EyeOff size={20} /> }
                                            </span>
                                        </div>
                                        <div
                                            onClick={() => { navigate('/user-forgot-password') }}
                                            style={{ display: 'flex', paddingTop: '12px', justifyContent: 'flex-end' }}
                                        >
                                            <p
                                                style={{
                                                    color: '#064BB5',
                                                    cursor: 'pointer',
                                                    fontFamily: 'Instrument Sans, sans-serif',
                                                    fontWeight: 400,
                                                    fontSize: '13px',
                                                    lineHeight: '15.86px',
                                                    float: 'right'
                                                }}
                                            >
                                                Forgot Password
                                            </p>
                                        </div>

                                        <br />
                                        {IsUploading ? (
                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 50 }}>
                                                <ClipLoader color="#31ED31" size={50} />
                                            </div>
                                        ) : (
                                            <button className='tab-btn active' onClick={Login}>Log in</button>
                                        )}
                                        <br />
                                        <br />
                                    </>
                                }

                                {
                                    LoginType === "Number" &&
                                    <>
                                        <label>Phone Number </label>
                                        <br />
                                        <input
                                            placeholder='Phone Number'
                                            value={phoneNumber}
                                            onChange={(e) => setphoneNumber(e.target.value)}
                                        />
                                        <br />
                                        <br />
                                        {
                                            Showotp &&
                                            <>
                                                <label>OTP </label>
                                                <br />
                                                <input
                                                    placeholder='Enter OTP'
                                                    type='OTP'
                                                    value={otp}
                                                    onChange={(e) => setotp(e.target.value)}
                                                />
                                            </>
                                        }

                                        <br />
                                        <br />
                                        {IsUploading ? (
                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 50 }}>
                                                <ClipLoader color="#31ED31" size={50} />
                                            </div>
                                        ) : (
                                            Showotp ? (
                                                <button className='tab-btn active' onClick={VerifyOtp}>Verify OTP</button>
                                            ) : (
                                                <button className='tab-btn active' onClick={SendOtp}>Send OTP</button>
                                            )
                                        )}
                                        <br />
                                        <br />
                                        {Showotp && (
                                            <p onClick={resendCountdown === 0 ? ResendOtp : null}>
                                                Resend OTP {resendCountdown > 0 ? `(${resendCountdown}s)` : ''}
                                            </p>
                                        )}
                                        <br />
                                        <br />
                                    </>
                                }

                                <p>Don’t Have an Account?</p>
                                <br />
                                <Link style={{ textDecoration: 'none' }} to='/signup'>
                                    <h4>Create Account?</h4>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default UserLogin